import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LONG_POLLING_INTERVAL } from '../../../constants';
import Choropleth from 'common/components/Choropleth/Choropleth';
import { Coordinates, Bounds, RegionProperties } from 'types/choropleth-types';
import { USRegions } from './GeoJsonRegionsUSA';
import DisplaySelector from './DisplaySelector/DisplaySelector';
import { mapDataToRegion } from './mapDataToRegion';
import {
  fetchRegionalDelayUpdates,
  syncLiveBorderCrossingDelay,
  changeRegionalDisplayMode,
} from 'actions';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import {
  ROUTE_REGIONAL_INSIGHTS,
} from '../../../constants';
import CityStateItem from './CityStateItem';

type CityStateState = {
  delayUpdates: RegionProperties[];
  isDelayPending: boolean;
  isDelayError: boolean;
  activeMode: string;
  displayMode: string;
};

type StateProps = {
  delayUpdates: RegionProperties[];
  isDelayPending: boolean;
  isDelayError: boolean;
  activeMode: string;
  displayMode: string;
};

type DispatchProps = {
  fetchRegionalDelayUpdates: Function;
  syncLiveBorderCrossingDelay: Function;
  changeRegionalDisplayMode: Function;
};

type CityStateProps = StateProps & DispatchProps;

class CityState extends React.Component<CityStateProps, CityStateState> {
  public longInterval: NodeJS.Timeout | null = null;

  constructor(props: CityStateProps) {
    super(props);
    this.state = {
      delayUpdates: [],
      isDelayPending: false,
      isDelayError: false,
      activeMode: '',
      displayMode: props.displayMode || 'inbound',
    };
    this.props.changeRegionalDisplayMode(this.state.displayMode);
  }

  componentDidMount(): void {
    this.props.fetchRegionalDelayUpdates();
    this.longPollingUpdates();
  }

  longPollingUpdates(): void {
    this.longInterval = setInterval(() => {
      this.props.fetchRegionalDelayUpdates();
    }, LONG_POLLING_INTERVAL);
  }

  componentWillUnmount(): void {
    this.clearLongPolling();
  }

  clearLongPolling(): void {
    if (this.longInterval) {
      clearInterval(this.longInterval);
    }
  }

  componentDidUpdate(prevProps: Readonly<CityStateProps>): void {
    const {
      delayUpdates,
      isDelayPending,
      isDelayError,
      syncLiveBorderCrossingDelay,
      displayMode,
    } = this.props;
    if (prevProps.delayUpdates !== delayUpdates) {
      this.setState({
        delayUpdates: delayUpdates,
      });
    }
    if (prevProps.isDelayPending !== isDelayPending) {
      this.setState({
        isDelayPending: isDelayPending,
      });
    }
    if (prevProps.isDelayError !== isDelayError) {
      this.setState({
        isDelayError: isDelayError,
      });
    }
    if (prevProps.displayMode !== displayMode) {
      this.setState({
        displayMode: displayMode,
      });
    }
    if (!isDelayPending && !isDelayError) {
      syncLiveBorderCrossingDelay();
      this.clearLongPolling();
      this.longPollingUpdates();
    }
  }

  getBounds(): Bounds {
    const nearVancouver: Coordinates = { latitude: 49, longitude: -124 };
    const nearKeyWest: Coordinates = { latitude: 24, longitude: -66 };
    const unitedStatesBounds: Bounds = {
      upperLeft: nearVancouver,
      bottomRight: nearKeyWest,
    };

    return unitedStatesBounds;
  }

  generateChloropleth(): JSX.Element {
    const regionProperties = mapDataToRegion(this.props.delayUpdates, USRegions);
    return (
      <Choropleth
        bounds={this.getBounds()}
        regions={regionProperties}
        displayMode={this.props.displayMode}
      />
    );
  }

  changeDisplayMode(displayMode: string): void {
    this.props.changeRegionalDisplayMode(displayMode);
    this.setState({ displayMode: displayMode });
  }

  renderTrendSection(){
    return (
      <Route path={`${process.env.PUBLIC_URL}/${ROUTE_REGIONAL_INSIGHTS}/:id`}>
        <CityStateItem />
      </Route>
    );
  }

  render(): JSX.Element {
    return (
      <div className="map-wrapper" data-testid="city-state-component">
        <DisplaySelector
          currentMode={this.props.displayMode}
          changeDisplayMode={this.changeDisplayMode.bind(this)}
          className="display-selector"
        />
        {this.generateChloropleth()}
        {this.renderTrendSection()}
      </div>
    );
  }
}

function mapStateToProps(state: any): StateProps {
  return {
    delayUpdates: state.regionalDelayUpdates.data,
    isDelayPending: state.regionalDelayUpdates.pending,
    isDelayError: state.regionalDelayUpdates.error,
    activeMode: state.activeMode.activeMode,
    displayMode: state.regionalDelayUpdates.displayMode,
  };
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return bindActionCreators(
    {
      fetchRegionalDelayUpdates,
      syncLiveBorderCrossingDelay,
      changeRegionalDisplayMode,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(CityState);
