import {
  BorderCrossingEventsUpdates,
  BorderCrossingDelayUpdates,
} from '../types/border-cross-types';

export const BORDER_CROSS_EVENT_UPDATES: BorderCrossingEventsUpdates = {
  borderCrossingEventsUpdates: [
    {
      dateTime: 'Coming Soon',
      update: `Events updates coming very soon`,
    } /*, {
        dateTime: 'Mar 21, 2020 14:00',
        update: `A looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time`
        }, {
            dateTime: 'Jun 22, 2020 17:32 IST',
            update: `Next Best Things`
        }, {
            dateTime: 'Mar 21, 2020 14:00',
            update: `A looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time`
        }, {
            dateTime: 'Mar 21, 2020 14:00',
            update: `A looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time`
        }, {
            dateTime: 'Mar 21, 2020 14:00',
            update: `A looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time`
        }, {
            dateTime: 'Mar 21, 2020 14:00',
            update: `A looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time`
        }*/,
  ],
};

export const BORDER_CROSS_DELAYS_UPDATES: BorderCrossingDelayUpdates = {
  borderCrossingDelayUpdates: [
    {
      borderId: 100,
      borderName: 'Beaver Creek',
      lat: 62.58,
      lng: -141.0392,
      waitingTimeInSeconds: 3600,
      calculatedAt: '2020-03-21T17:27:56',
      colorCode: '#FEA53F',
    },
    {
      borderId: 200,
      borderName: 'Douglas',
      lat: 49.07,
      lng: -123.4566,
      waitingTimeInSeconds: 3600,
      calculatedAt: '2020-03-21T17:27:56',
      colorCode: '#E65100',
    },
    {
      borderId: 300,
      borderName: 'Aden',
      lat: 48.59,
      lng: -111.1531,
      waitingTimeInSeconds: 3600,
      calculatedAt: '2020-03-21T17:27:56',
      colorCode: '#26C572',
    },
    {
      borderId: 400,
      borderName: 'Canada-Vancouver',
      lat: 49.00119,
      lng: -123.06761,
      waitingTimeInSeconds: 3600,
      calculatedAt: '2020-03-21T17:27:56',
      colorCode: 'blue',
    },
    {
      borderId: 500,
      borderName: 'Canda-Little Gold Creek',
      lat: 64.58,
      lng: -141.0487,
      waitingTimeInSeconds: 3600,
      calculatedAt: '2020-03-21T17:27:56',
      colorCode: '#E65100',
    },
    {
      borderId: 600,
      borderName: 'Emerson',
      lat: 49.018,
      lng: -97.33252,
      waitingTimeInSeconds: 3600,
      calculatedAt: '2020-03-21T17:27:56',
      colorCode: '#E65100',
    },
    {
      borderId: 700,
      borderName: 'Cornwall',
      lat: 44.5926,
      lng: -74.4422,
      waitingTimeInSeconds: 3600,
      calculatedAt: '2020-03-21T17:27:56',
      colorCode: '#26C572',
    },
    {
      borderId: 800,
      borderName: 'Detroit - Ambassador Bridge',
      lat: 42.1842,
      lng: -83.42639,
      waitingTimeInSeconds: 3600,
      calculatedAt: '2020-03-21T17:27:56',
      colorCode: '#FEA53F',
    },
    {
      borderId: 900,
      borderName: 'Armstrong',
      lat: 45.2243,
      lng: -70.2348,
      waitingTimeInSeconds: 3600,
      calculatedAt: '2020-03-21T17:27:56',
      colorCode: '#FEA53F',
    },
    {
      borderId: 900,
      borderName: 'Upper mills',
      lat: 45.11,
      lng: -67.1729,
      waitingTimeInSeconds: 3600,
      calculatedAt: '2020-03-21T17:27:56',
      colorCode: '#B71C1C',
    },
    {
      borderId: 1000,
      borderName: 'Tinker',
      lat: 46.47,
      lng: -67.472256,
      waitingTimeInSeconds: 3600,
      calculatedAt: '2020-03-21T17:27:56',
      colorCode: 'blue',
    },
    {
      borderId: 1100,
      borderName: 'Carbury',
      lat: 48.59,
      lng: -100.332,
      waitingTimeInSeconds: 3600,
      calculatedAt: '2020-03-21T17:27:56',
      colorCode: '#B71C1C',
    },
    {
      borderId: 1101,
      borderName: 'Boissevain',
      lat: 48.59,
      lng: -100.3828,
      waitingTimeInSeconds: 3600,
      calculatedAt: '2020-03-21T17:27:56',
      colorCode: '#26C572',
    },
    {
      borderId: 1102,
      borderName: 'Rainy River',
      lat: 48.43,
      lng: -94.3525,
      waitingTimeInSeconds: 3600,
      calculatedAt: '2020-03-21T17:27:56',
      colorCode: '#FEA53F',
    },
  ],
};
