import { SYNC_LIVE_TIME_UPDATES } from '../constants';
import moment from 'moment';
export function SyncLiveTimeReducer(state = {}, action: { type: string }) {
  switch (action.type) {
    case SYNC_LIVE_TIME_UPDATES:
      return {
        ...state,
        syncLiveTime: moment().format('DD MMM YYYY, hh:mm:ss'),
      };
  }
  return state;
}
