import React from 'react';

import { titleCase, kebabCase, snakeCase } from '../../../constants/formatters';

type ToggleOptionProp = {
  mode: string;
  active: boolean;
  region: string;
  selectMode: Function;
} & DefaultProps;

const defaultProps = {
  region: '',
};

type DefaultProps = Readonly<typeof defaultProps>;

class ToggleOption extends React.Component<ToggleOptionProp> {
  static defaultProps = defaultProps;

  createButtonText(): string {
    return `${titleCase(this.props.mode)} ${this.props.region}`.trim();
  }

  generateClassName(): string {
    return this.props.active ? 'Active' : 'Not-active';
  }

  generateImgPath(): string {
    const path = '/assets/img/';
    const modePath = snakeCase(this.props.mode);
    const inactive = '_inactive';
    const active = '_active';
    const extension = `.svg`;
    const color = this.props.active ? active : inactive;
    return path + modePath + color + extension;
  }

  render(): JSX.Element {
    const buttonText = this.createButtonText();
    const { mode, selectMode } = this.props;
    const modePath = kebabCase(mode);

    return (
      <button
        className={this.generateClassName()}
        onClick={(): void => selectMode(mode, modePath)}
        data-testid={`toggle-option-button-${modePath}`}
      >
        <img title={buttonText} src={this.generateImgPath()} alt="" />
        <span>{buttonText}</span>
      </button>
    );
  }
}

export default ToggleOption;
