import React from 'react';
import * as _ from 'lodash';
import './MapElement.scss';
import {
  Map,
  Marker,
  Popup,
  TileLayer,
  ZoomControl,
  LayersControl,
} from 'react-leaflet';
import {
  TILE_LAYER_URL,
  LayerControls,
} from '../../constants/map-config.constants';
import { BorderCrossDelay, Delay } from '../../../types/border-cross-types';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import * as L from 'leaflet';
import Legend from './MapLegend';
import Explore from './MapExplore';
import { ENUM_BORDER_DELAY_COLOR_CODES } from '../../../constants';
import { GENERAL_UTIL } from '../../../utils/general-util';
import LayerSwitcher from './MapLayerSwitcher';

interface Tile {
  attribution: string;
  tile: string;
}

interface MapState {
  lat: number;
  lng: number;
  position: [number, number];
  zoom: number;
  animate: boolean;
  zoomControl: boolean;
  minZoom: number;
  maxZoom: number;
  scrollWheelZoom: boolean;
  lightTileLayer: Tile;
  darkTileLayer: Tile;
  mode: string;
  modeValue: string;
}

export interface MapProps {
  borderCrossingDelayUpdates: BorderCrossDelay[];
  error: boolean;
  pending: boolean;
}

export class MapElement extends React.Component<MapProps, MapState> {
  public mapRef: any;
  constructor(props: MapProps) {
    super(props);
    const modeObj = GENERAL_UTIL.getThemeObj();
    this.state = {
      lat: 41.89711,
      lng: -87.63023,
      zoom: 10,
      position: [41.89711, -87.63023] as [number, number],
      animate: true,
      zoomControl: false,
      minZoom: 2,
      maxZoom: 18,
      scrollWheelZoom: true,
      lightTileLayer: TILE_LAYER_URL({
        resource: 'trucknopttile',
        scheme: 'reduced.day',
        resolution: 512,
        ppi: 400,
      }),
      darkTileLayer: TILE_LAYER_URL({
        resource: 'trucknopttile',
        scheme: 'reduced.night',
        resolution: 512,
        ppi: 400,
      }),
      mode: modeObj.className,
      modeValue: modeObj.value,
    };
    this.mapRef = React.createRef();
  }

  checkMapData() {
    setTimeout(() => {
      if (!this.props.pending || this.props.error) {
        this.mapRef.current.leafletElement.invalidateSize();
        this.removeEventListener();
        this.mapRef.current.leafletElement.on('baselayerchange', (e: any) => {
          let mode;
          let modeValue;
          if (e.name === 'dark') {
            mode = LayerControls[1].className;
            modeValue = LayerControls[1].value;
          } else {
            mode = LayerControls[0].className;
            modeValue = LayerControls[0].value;
          }
          GENERAL_UTIL.storeTheme(modeValue);
          this.setState({
            mode,
            modeValue,
          });
        });
      }
    });
  }

  componentDidMount() {
    this.checkMapData();
  }

  componentDidUpdate(): void {
    this.checkMapData();
  }

  removeEventListener() {
    if (
      this.mapRef &&
      this.mapRef.current &&
      this.mapRef.current.leafletElement
    ) {
      this.mapRef.current.leafletElement.off('baselayerchange');
    }
  }

  getBounds(data = []) {
    if (_.isEmpty(data)) {
      return null;
    }
    return data.map((d: BorderCrossDelay) => [d.lat, d.lng]);
  }

  getTimeText(mins: number | null) {
    if (mins === -1) {
      return `No Recent Loads`;
    } else if (mins === 0) {
      return `No Delay`;
    } else if (mins === null) {
      return '--';
    }
    return GENERAL_UTIL.getRemainingTime(mins);
  }

  createMarkers(data = []) {
    return data.map((d: BorderCrossDelay, i: number) => {
      const [toDelay, froDelay] = this.prepareDelayhash(d);
      const position = [d.lat, d.lng] as [number, number];
      const originWaitTime =
        toDelay.waitTime === undefined ? null : toDelay.waitTime;
      const originHistWait =
        toDelay.histWait === undefined ? null : toDelay.histWait;
      const destWaitTime =
        froDelay.waitTime === undefined ? null : froDelay.waitTime;
      const destHistWait =
        froDelay.histWait === undefined ? null : froDelay.histWait;

      const myIcon = L.divIcon({
        html: GENERAL_UTIL.getBorderCrossingSvg(d.colorCode),
        iconSize: [25, 25],
        iconAnchor: [12, 25],
        popupAnchor: [0, -30],
        className: d.colorCode,
      });
      return (
        <Marker
          position={position}
          draggable={false}
          icon={myIcon}
          key={i}
          onMouseOver={(e: any) => {
            e.target.openPopup();
          }}
          onMouseOut={(e: any) => {
            e.target.closePopup();
          }}
        >
          <Popup>
            <div className="Border-name">{d.borderName} </div>
            {this.getPopupContent(
              originWaitTime,
              toDelay,
              'Origin-destination-wait-time',
              originWaitTime,
              originHistWait,
            )}
            {this.getPopupContent(
              destWaitTime,
              froDelay,
              'Destination-origin-wait-time',
              destWaitTime,
              destHistWait,
            )}
          </Popup>
        </Marker>
      );
    });
  }

  prepareDelayhash(d: BorderCrossDelay): [Delay, Delay] {
    const defaultDelayHash = {
      waitTime: undefined,
      histWait: undefined,
      origin: '',
      dest: '',
    } as unknown;
    if (!d.delay || !d.delay.length) {
      return [defaultDelayHash as Delay, defaultDelayHash as Delay];
    }
    const toDelay: Delay =
      d.delay && d.delay[0] ? d.delay[0] : (defaultDelayHash as Delay);
    const origin = toDelay['dest'];
    const dest = toDelay['origin'];
    const froDelay =
      d.delay && d.delay[1]
        ? d.delay[1]
        : ({
            origin,
            dest,
            waitTime: undefined,
            histWait: undefined,
          } as unknown);
    return [toDelay, froDelay as Delay];
  }

  getAverageTime(waitTime: number | null, histWait: number | null) {
    if (waitTime === null || histWait === null) {
      return 'No recent data available';
    } else if (waitTime === -1) {
      return '';
    }
    const mins = waitTime - histWait;
    const str = mins >= 0 ? 'above' : 'below';
    return `${GENERAL_UTIL.getRemainingTime(Math.abs(mins))} ${str} average`;
  }

  getPopupContent(
    diff: number | null,
    delay: Delay,
    className: string,
    waitTime: number | null,
    histWait: number | null,
  ) {
    return (
      <div className={className} style={this.getColorCode(diff)}>
        <span> {delay ? delay.origin : ''} </span>
        <span className="Divider">
          <i className="fa fa-chevron-right" aria-hidden="true"></i>
        </span>
        <span> {delay ? delay.dest : ''} </span>
        <br />
        <span className="Time-in-mins">{this.getTimeText(waitTime)}</span>
        <br />
        <span className="Historical-wait-time">
          {this.getAverageTime(waitTime, histWait)}
        </span>
      </div>
    );
  }
  createClusterCustomIcon(cluster: any) {
    const count = cluster.getChildCount();
    const clusterClass = getClusterColorClass(cluster);
    const options = {
      cluster: `Marker-cluster ${clusterClass}`,
    };

    return L.divIcon({
      html: `<div>
          <span class="Marker-cluster-label">${count}</span>
        </div>`,
      className: `${options.cluster}`,
    });
  }

  createCluster(data = []) {
    if (_.isEmpty(data)) {
      return;
    }
    return (
      <MarkerClusterGroup
        showCoverageOnHover={false}
        iconCreateFunction={this.createClusterCustomIcon}
      >
        {this.createMarkers(data)}
      </MarkerClusterGroup>
    );
  }

  getColorCode(waitTime: number | null) {
    let color = ENUM_BORDER_DELAY_COLOR_CODES.NO_ETA;
    if (waitTime === null) {
      return { color };
    } else if (waitTime >= 0 && waitTime < 15) {
      color = ENUM_BORDER_DELAY_COLOR_CODES.EARLY;
    } else if (waitTime >= 15 && waitTime < 60) {
      color = ENUM_BORDER_DELAY_COLOR_CODES.ON_TIME;
    } else if (waitTime >= 60 && waitTime < 1440) {
      color = ENUM_BORDER_DELAY_COLOR_CODES.LATE;
    } else if (waitTime >= 1440) {
      color = ENUM_BORDER_DELAY_COLOR_CODES.VERY_LATE;
    }
    return { color };
  }

  render() {
    const borderCrossingDelayUpdates = this.props
      .borderCrossingDelayUpdates as any;
    const markersBound: any = this.getBounds(borderCrossingDelayUpdates) || [
      [48.43, -94.3525],
      [41.89711, -87.63023],
    ];
    const bounds = new L.LatLngBounds(markersBound).pad(0.1);

    if (this.props.pending) {
      return <MapLoader />;
    }

    return (
      <div className={`Map-updates ${this.state.mode}`}>
        <Map
          scrollWheelZoom={this.state.scrollWheelZoom}
          ref={this.mapRef}
          maxZoom={this.state.maxZoom}
          minZoom={this.state.minZoom}
          zoom={this.state.zoom}
          zoomControl={this.state.zoomControl}
          animate={this.state.animate}
          bounds={bounds}
          maxBounds={bounds}
        >
          <LayersControl position="topright">
            <LayersControl.BaseLayer name="light" checked={true}>
              <TileLayer
                attribution={this.state.lightTileLayer.attribution}
                url={this.state.lightTileLayer.tile}
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="dark">
              <TileLayer
                attribution={this.state.darkTileLayer.attribution}
                url={this.state.darkTileLayer.tile}
              />
            </LayersControl.BaseLayer>
          </LayersControl>
          {this.createCluster(borderCrossingDelayUpdates)}
          <ZoomControl position="topright" />
          <LayerSwitcher mapElement={this.mapRef} mode={this.state.modeValue} />
        </Map>
        <Legend type={"border"}></Legend>
        <Explore></Explore>
      </div>
    );
  }
}

function MapLoader() {
  return (
    <div className="Map-updates">
      <div className="fa-3x Map-error">
        <i className="fas fa-spinner fa-spin"></i>
      </div>
    </div>
  );
}

function getClusterColorClass(cluster: any) {
  const colorCodes: any = cluster
    .getAllChildMarkers()
    .map((c: any) => c.options.icon.options.className.toUpperCase());
  if (colorCodes.indexOf(ENUM_BORDER_DELAY_COLOR_CODES['VERY_LATE']) > -1) {
    return 'Very-late';
  }
  if (colorCodes.indexOf(ENUM_BORDER_DELAY_COLOR_CODES['LATE']) > -1) {
    return 'Late';
  }
  if (colorCodes.indexOf(ENUM_BORDER_DELAY_COLOR_CODES['ON_TIME']) > -1) {
    return 'On-time';
  }
  if (colorCodes.indexOf(ENUM_BORDER_DELAY_COLOR_CODES['EARLY']) > -1) {
    return 'Early';
  }
  return ``;
}

export default MapElement;
