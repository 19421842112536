import React from 'react';
import './Header.scss';
import moment from 'moment';
import {
  fetchingBorderCrossingDelayUpdates,
  fetchingOceanPortCongestionDetails,
  fetchRegionalDelayUpdates,
  setActiveMode,
} from '../../../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  MODES,
  TITLE_BORDER_CROSSING,
  TITLE_PORT_CONGESTION,
  TITLE_REGIONAL_INSIGHTS,
} from '../../../constants';
import { withRouter } from 'react-router-dom';
import { History } from 'history';
import ToggleOption from '../ToggleOption/ToggleOption';

type StateProps = {
  lastUpdatedTime: string;
};

type DispatchProps = {
  fetchingBorderCrossingDelayUpdates: Function;
  // fetchingOceanPortCongestionDetails: Function;
  fetchRegionalDelayUpdates: Function;
  setActiveMode: Function;
};

type OwnProps = {
  history: History;
};

type HeaderProps = StateProps & DispatchProps & OwnProps;

type HeaderState = {
  title: string;
  lastUpdatedTime: string;
  selectedMode: string;
};

class Header extends React.Component<HeaderProps, HeaderState> {
  constructor(props: HeaderProps) {
    super(props);
    this.state = {
      title: TITLE_BORDER_CROSSING,
      lastUpdatedTime: moment().format('DD MMM YYYY, hh:mm:ss'),
      selectedMode: MODES.REGIONAL_INSIGHTS,
    };
    this.props.setActiveMode(this.state.selectedMode);
  }

  componentDidMount(): void {
    const path = this.props.history.location.pathname;
    let currentMode = path.replace('/', '').replace('-', '_').replace(/\/[0-9]+/, '').toUpperCase();
    currentMode = currentMode || MODES.REGIONAL_INSIGHTS;
    this.setState({
      selectedMode: currentMode,
    });
  }

  componentDidUpdate(prevProps: Readonly<HeaderProps>): void {
    if (prevProps.lastUpdatedTime !== this.props.lastUpdatedTime) {
      this.setState({ lastUpdatedTime: this.props.lastUpdatedTime });
    }
  }

  updateTime(): void {
    const mode = this.state.selectedMode;
    switch (mode) {
      case MODES.BORDER_CROSSING:
        this.props.fetchingBorderCrossingDelayUpdates();
        break;
      // case MODES.PORT_CONGESTION:
      //   this.props.fetchingOceanPortCongestionDetails();
      //   break;
      case MODES.REGIONAL_INSIGHTS:
        this.props.fetchRegionalDelayUpdates();
        break;
      default:
        break;
    }
  }

  navigateToFourkitesHome(): void {
    const URL = `https://www.fourkites.com/`;
    window.open(URL, '_blank');
  }

  updateState = (mode: string, path: string): void => {
    this.props.setActiveMode(mode);
    this.setState({ selectedMode: mode });
    this.props.history.push(`/${path}`);
  };

  renderButton(): JSX.Element {
    const isSelected = (mode: string): boolean =>
      this.state.selectedMode === mode;
    return (
      <div className="Button-wrapper">
        <ToggleOption
          mode={MODES.BORDER_CROSSING}
          selectMode={this.updateState}
          active={isSelected(MODES.BORDER_CROSSING)}
        />
        <ToggleOption
          mode={MODES.REGIONAL_INSIGHTS}
          selectMode={this.updateState}
          active={isSelected(MODES.REGIONAL_INSIGHTS)}
        />
        {/* <ToggleOption
          mode={MODES.PORT_CONGESTION}
          selectMode={this.updateState}
          active={isSelected(MODES.PORT_CONGESTION)}
        /> */}
      </div>
    );
  }

  renderTitle(): string {
    switch (this.state.selectedMode) {
      case MODES.BORDER_CROSSING:
        return TITLE_BORDER_CROSSING;
      case MODES.PORT_CONGESTION:
        return TITLE_PORT_CONGESTION;
      case MODES.REGIONAL_INSIGHTS:
        return TITLE_REGIONAL_INSIGHTS;
      default:
        return '';
    }
  }

  renderLastUpdatedTime(): JSX.Element | null {
    if(this.state.selectedMode === MODES.REGIONAL_INSIGHTS){
      const today = moment();
      const yesterday = today.subtract('days', 1);
      const lastUpdatedDate = yesterday.format('DD MMM YYYY');
      return (
        <div className="Updated-Time" style={{ textAlign: "right" }}>
          <span>Load trends for <b>{lastUpdatedDate}</b></span>
        </div>
      );
    }
    return (
      <div className="Updated-Time">
        Last updated on {this.state.lastUpdatedTime}
      </div>
    );
  }

  renderRefresher(): JSX.Element | null {
    if(this.state.selectedMode === MODES.REGIONAL_INSIGHTS){
      return null;
    }
    return (
      <div title="Refresh" className="Refresh">
        <div onClick={this.updateTime.bind(this)}>
          <i className="fas fa-redo-alt"></i>
        </div>
      </div>
    );
  }

  render(): JSX.Element | null {
    return (
      <div className="Header-wrapper">
        <header className="Header">
          <section
            className="Logo"
            onClick={this.navigateToFourkitesHome.bind(this)}
          >
            <img
              src="https://assets.fourkites.com/fk-logo/new_fourkites_logo_light_bg_220_60.svg"
              alt="Fourkites Logo"
            />
          </section>
          <section className="switchButton">{this.renderButton()}</section>
          <section className="Title-Description">
            {this.renderTitle() !== "" && <div className="Title">
              <span>{this.renderTitle()}</span>
            </div>}
            {this.renderLastUpdatedTime()}
            {this.renderRefresher()}
          </section>
        </header>
      </div>
    );
  }
}

function mapStateToProps(state: any): StateProps {
  return {
    lastUpdatedTime: state.syncLiveTime.syncLiveTime,
  };
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return bindActionCreators(
    {
      fetchingBorderCrossingDelayUpdates,
      fetchingOceanPortCongestionDetails,
      fetchRegionalDelayUpdates,
      setActiveMode,
    },
    dispatch,
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
