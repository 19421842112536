import { camelCase } from 'constants/formatters';

export function changeKeys(apiObject: any): any {
  const oldKeys = Object.keys(apiObject);
  const newKeys = oldKeys.map((key) => camelCase(key));

  oldKeys.forEach((oldKey, index) => {
    const newKey = newKeys[index];
    if (oldKey !== newKey) {
      apiObject[newKey] = apiObject[oldKey];
      delete apiObject[oldKey];
    }
  });
  return apiObject;
}
