import { combineReducers } from 'redux';
import { BorderCrossingEventsReducer } from './border-crossing-events.reducer';
import { BorderCrossingDelayReducer } from './border-crossing-delay.reducer';
import { SyncLiveTimeReducer } from './sync-live-time.reducer';
import { OceanPortAddressesReducer } from './ocean-port-addresses.reducer';
import { RegionalDelayReducer } from './regional-delay-reducer';
import { RegionalCityDelayReducer } from './regional-city-delay-reducer';
import { CityInsightsReducer } from './city-insights-reducer';

function setActiveModeReducer(
  state = { activeMode: '' },
  action: { type: string; payload: string },
) {
  switch (action.type) {
    case 'SELECTED_MODE':
      return { ...state, activeMode: action.payload };
  }
  return state;
}
const rootReducer = combineReducers({
  eventsUpdates: BorderCrossingEventsReducer,
  delayUpdates: BorderCrossingDelayReducer,
  regionalDelayUpdates: RegionalDelayReducer,
  cityDelayUpdates: RegionalCityDelayReducer,
  oceanPortAddresses: OceanPortAddressesReducer,
  syncLiveTime: SyncLiveTimeReducer,
  activeMode: setActiveModeReducer,
  cityInsights: CityInsightsReducer
});

export default rootReducer;
