function parseText(rawText: string): Array<string> {
  return rawText
    .toLowerCase()
    .split(/ |_|-/)
    .filter((word) => {
      const badValues = [undefined, null, ''];
      return !badValues.includes(word);
    });
}

function titleCaseWordArray(wordArray: string[]): string[] {
  for (let i = 0; i < wordArray.length; i++) {
    const firstLetter = wordArray[i][0];
    const remainingLetters = wordArray[i].slice(1);
    wordArray[i] = firstLetter.toUpperCase() + remainingLetters.toLowerCase();
  }
  return wordArray;
}

export function titleCase(text: string): string {
  const wordArray = parseText(text);

  return titleCaseWordArray(wordArray).join(' ');
}

export function kebabCase(text: string): string {
  const wordArray = parseText(text);

  for (let i = 0; i < wordArray.length; i++) {
    wordArray[i] = wordArray[i].toLowerCase();
  }

  return wordArray.join('-');
}

export function snakeCase(text: string): string {
  const wordArray = parseText(text);

  for (let i = 0; i < wordArray.length; i++) {
    wordArray[i] = wordArray[i].toLowerCase();
  }

  return wordArray.join('_');
}

export function camelCase(text: string): string {
  const wordArray = parseText(text);

  const firstWord = wordArray.shift() as string;

  const remainingWords = titleCaseWordArray(wordArray);

  remainingWords.unshift(firstWord);

  return remainingWords.join('');
}
