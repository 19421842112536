import { RegionalDelayAction } from '../types/border-cross-actions';
import {
  FETCH_REGIONAL_DELAY_UPDATES,
  FETCH_REGIONAL_DELAY_UPDATES_FAILURE,
  FETCH_REGIONAL_DELAY_UPDATES_FULFILLED,
  FETCH_REGIONAL_DELAY_UPDATES_PENDING,
  FETCH_REGIONAL_DELAY_UPDATES_REJECTED,
  CHANGE_REGIONAL_DISPLAY_MODE,
} from '../constants';
import { changeKeys } from './translators/regionalDelayUpdatesTranslator';

const initialState = {
  pending: false,
  data: [],
  error: null,
  displayMode: '',
};
export function RegionalDelayReducer(
  state = initialState,
  action: RegionalDelayAction,
): any {
  switch (action.type) {
    case FETCH_REGIONAL_DELAY_UPDATES:
      return {
        ...state,
        pending: true,
        error: null,
        data: [],
      };
    case FETCH_REGIONAL_DELAY_UPDATES_PENDING:
      return {
        ...state,
        pending: true,
        data: [],
        error: null,
      };
    case FETCH_REGIONAL_DELAY_UPDATES_FULFILLED:
      action.payload.data.forEach((region: any) => {
        region.delay = changeKeys(region.delay);
      });

      const regionalDelayUpdates = action.payload.data || [];
      return {
        ...state,
        pending: false,
        data: regionalDelayUpdates,
      };
    case FETCH_REGIONAL_DELAY_UPDATES_REJECTED:
      return {
        ...state,
        pending: false,
        data: [],
        error: action.payload.message,
      };
    case FETCH_REGIONAL_DELAY_UPDATES_FAILURE:
      return {
        ...state,
        pending: false,
        data: [],
        error: action.payload.message,
      };
    case CHANGE_REGIONAL_DISPLAY_MODE:
      return {
        ...state,
        displayMode: action.payload,
      };
  }
  return state;
}
