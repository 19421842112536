import * as _ from 'lodash';

export const HereMapAuthToken = {
  apikey: 'NYBxKMPwfN1wCv4M9XHyZSeCjA1a9Vj5GNH5UjeYKBA'
};

export const MapBoxConfig = {
  mapboxAccessToken:
    'pk.eyJ1IjoiaW1raXJhbjIyIiwiYSI6ImNqZzRxaDRseDdybHMycW1zZ3RpdDlxMjYifQ.C2PQ36BtOIv5xPoXJlneDg',
};

export const LayerControls: {
  key: string;
  value: string;
  className: string;
}[] = [
  {
    key: 'Light',
    value: 'light',
    className: 'Light-mode',
  },
  {
    key: 'Dark',
    value: 'dark',
    className: 'Dark-mode',
  },
];

export const MapBoxLayers = [
  { scheme: 'mapbox.satellite' },
  { scheme: 'mapbox.streets' },
  { scheme: 'mapbox.light' },
  { scheme: 'mapbox.dark' },
  { scheme: 'mapbox.satellite' },
  { scheme: 'mapbox.streets-satellite' },
  { scheme: 'mapbox.wheatpaste' },
  { scheme: 'mapbox.comic' },
  { scheme: 'mapbox.outdoors' },
  { scheme: 'mapbox.run-bike-hike' },
  { scheme: 'mapbox.pencil' },
  { scheme: 'mapbox.pirates' },
  { scheme: 'mapbox.emerald' },
  { scheme: 'mapbox.high-contrast' },
  { scheme: 'mapbox.streets-basic' },
];

const getStyleFromScheme = (scheme: string) => {
  let style = "explore.day";
  switch (scheme) {
    case "reduced.night":
      style = "lite.night";
      break;
    case "terrain.day":
      style = "topo.day";
      break;
    case "normal.day":
      style = "explore.day";
      break;
    case "hybrid.day":
      style = "explore.satellite.day";
      break;
    case "normal.traffic.day":
      style = "lite.day";
      break;
    default:
      style = "explore.day";
  }
  return style;
}

const getResourse = (v2Resource: string) => {
  let resource = "base";
  let resourceParams = "";
  switch (v2Resource) {
    case "basetile":
      resource = "background";
      break;
    case "labeltile":
      resource = "label";
      break;
    case "maptile":
    case "mapnopttile":
      resource = "base";
      break;
    case "trucktile":
    case "trucknopttile":
      resource = "base";
      resourceParams = "&features=vehicle_restrictions:active_and_inactive";
      break;
    case "truckonlytile":
      resource = "blank";
      resourceParams = "&features=vehicle_restrictions:active_and_inactive";
      break;
    default:
      resource = "base";
      resourceParams = "";
  }
  return { resource, resourceParams };
}

export const TILE_LAYER_URL = (
  options: {
    scheme: string;
    resource: string;
    resolution?: number;
    ppi?: number;
  } = {
    scheme: 'terrain.day',
    resource: 'trucknopttile',
  },
) => {
  const appKey = HereMapAuthToken.apikey;
  const tileResolution = options.resolution || 256;
  const format = 'png8';
  const ppi = options.ppi || 100; //100 is the default value set by heremaps
  let style = getStyleFromScheme(options.scheme);
  let { resource, resourceParams } = getResourse(options.resource);
  const tilePath = `/v3/${resource}/mc/{z}/{x}/{y}/${format}?style=${style}&lang=en&apiKey=${appKey}${resourceParams}&ppi=${ppi}&size=${tileResolution}`;
  const tileServer = "maps.hereapi.com";
  const attributionPath = `https://developer.here.com/`;

  return {
    tile: `https://${tileServer}${tilePath}`,
    attribution: `<a href="${attributionPath}">HERE</a>`,
  };
};
