import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { LONG_POLLING_INTERVAL } from '../../../constants';
import { RegionTrend, Coordinates, Bounds, RegionProperties, CityProperties } from 'types/choropleth-types';
import IndustryTrendChart from 'common/components/Choropleth/IndustryTrendChart';
import CityInsights from 'common/components/Choropleth/CityInsights';
import {
  fetchRegionalCityDelayUpdates,
  changeRegionalDisplayMode,
  fetchCityInsights
} from 'actions';
import './CityStateItem.scss';

type UrlParams = {
  id: string
}

type CityStateItemState = {
  delayUpdates: RegionTrend | null;
  isDelayPending: boolean;
  isDelayError: boolean;
  activeMode: string;
  displayMode: string;
  cityInsightsUpdates: CityProperties
  isCityInsightsPending: boolean;
  isCityInsightsError: boolean;
}

type CityProps = {
  delayUpdates: RegionTrend | null;
  isDelayPending: boolean;
  isDelayError: boolean;
  activeMode: string;
  displayMode: string;
  match?: { params: UrlParams }
  location?: any
  cityInsightsUpdates: CityProperties
  isCityInsightsPending: boolean;
  isCityInsightsError: boolean;
}

type DispatchProps = {
  changeRegionalDisplayMode: Function,
  fetchRegionalCityDelayUpdates: Function,
  fetchCityInsights: Function
}

type CityStateItemProps = CityProps & DispatchProps;

class CityStateItem extends React.Component<CityStateItemProps, CityStateItemState> {
  public longInterval: NodeJS.Timeout | null = null;
  
  public get stateId(): string {
    return (this.props.match && this.props.match.params && this.props.match.params.id) || "";
  }

  constructor(props: CityStateItemProps) {
    super(props);
    this.state = {
      delayUpdates: null,
      isDelayPending: false,
      isDelayError: false,
      activeMode: '',
      displayMode: props.displayMode || 'inbound',
      cityInsightsUpdates: {
        inbound: [],
        outbound: []
      },
      isCityInsightsPending: false,
      isCityInsightsError: false
    };
    this.props.changeRegionalDisplayMode(this.state.displayMode);
  }
  
  componentDidMount(): void {
    this.props.fetchRegionalCityDelayUpdates(this.stateId);
    this.props.fetchCityInsights(this.stateId);
    this.longPollingUpdates();
  }

  componentDidUpdate(prevProps: CityStateItemProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.fetchRegionalCityDelayUpdates(this.stateId);
      this.props.fetchCityInsights(this.stateId);
    }
  }

  longPollingUpdates(): void {
    this.longInterval = setInterval(() => {
      this.props.fetchRegionalCityDelayUpdates(this.stateId);
      this.props.fetchCityInsights(this.stateId);
    }, LONG_POLLING_INTERVAL);
  }
  
  changeDisplayMode(displayMode: string): void {
    this.props.changeRegionalDisplayMode(displayMode);
    this.setState({ displayMode: displayMode });
  }

  render(): JSX.Element | null {
    if (this.props.delayUpdates && this.props.delayUpdates.trends && this.props.delayUpdates.state && 
      this.props.delayUpdates.state.delay && this.props.delayUpdates.state.delay[this.props.displayMode]) return (
     <div className="insights-panel">
      <IndustryTrendChart
        regionTrend={this.props.delayUpdates}
        currentMode={this.props.displayMode}
        />
        <CityInsights
          cityUpdates={this.props.cityInsightsUpdates}
          currentMode={this.props.displayMode}
        />
      </div>
    );
    return null;
  }
}

function mapStateToProps(state: any): CityStateItemState {
  return {
    delayUpdates: state.cityDelayUpdates.data,
    isDelayPending: state.cityDelayUpdates.pending,
    isDelayError: state.cityDelayUpdates.error,
    activeMode: state.activeMode.activeMode,
    displayMode: state.regionalDelayUpdates.displayMode,
    cityInsightsUpdates: state.cityInsights.data,
    isCityInsightsPending: state.cityInsights.pending,
    isCityInsightsError: state.cityInsights.error,
  };
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return bindActionCreators(
    {
      fetchRegionalCityDelayUpdates,
      changeRegionalDisplayMode,
      fetchCityInsights
    },
    dispatch,
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CityStateItem));