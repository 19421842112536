import ReactGA from 'react-ga';
const FKDEV_TRACKING_ID = 'UA-162422497-1';
const PRODUCTION_TRACKING_ID = 'UA-162422497-2';

export const INIT_GA = () => {
  if (process.env.REACT_APP_ENV === 'production') {
    ReactGA.initialize(PRODUCTION_TRACKING_ID, { debug: true });
  } else if (process.env.REACT_APP_ENV === 'fkdev') {
    ReactGA.initialize(FKDEV_TRACKING_ID, { debug: true });
  }
  const pageView = window.location.pathname + window.location.search;
  ReactGA.pageview(pageView);
  console.log('PAGE_VIEW', pageView);
};
