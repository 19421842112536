import React from 'react';
import './Footer.scss';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const baseUrl:string = `https://www.fourkites.com/`;

class Footer extends React.Component { 

	navigateToFourkitesHome(): void {
    const URL = baseUrl;
    window.open(URL, '_blank');
	}
	
	navigateToRealTimeVisibility(): void {
    const URL = baseUrl + `platform/real-time-visibility/`;
    window.open(URL, '_blank');
	}
	
	navigateToDynamicYard(): void {
    const URL = baseUrl + `platform/yard-management/`;
    window.open(URL, '_blank');
	}
	navigateToGetDemo(): void {
    const URL = baseUrl + `demo/`;
    window.open(URL, '_blank');
	}
	
	

	render(): JSX.Element | null {
		return (
				<div className="Footer-wrapper">
						<footer className="footer">
							<section className="Logo"
											 onClick={this.navigateToFourkitesHome.bind(this)}
									>
									<div className="align-right">Powered by<br/></div>
									<img
									src="https://assets.fourkites.com/fk-logo/new_fourkites_logo_light_bg_220_60.svg"
									alt="Fourkites Logo"
									/>
							</section>
								
						<div className="flex-container">
							<div className="flex-item-one">
							<div className="real-time-btn" onClick={this.navigateToRealTimeVisibility.bind(this)}>REAL-TIME VISIBILITY </div>
							</div>
							<div className="flex-item-two">
							<div className="dynamic-yard-btn" onClick={this.navigateToDynamicYard.bind(this)}>DYNAMIC YARD</div>
							</div>
							<div className="flex-item-three">
							<div className="agility">The State of Yard Management: <a href=" https://www.fourkites.com/the-state-of-yard-management-industry-report/" target="_blank">2021 Industry Report</a></div>
							</div>
							<div className="flex-item-four">
							<div className="get-demo-btn"><button className="button" onClick={this.navigateToGetDemo.bind(this)}>Get a Demo</button></div>
							</div>
						</div>
						
						</footer>
				</div>
		);
	}

}

  export default withRouter(connect()(Footer));