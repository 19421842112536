import { BorderCrossDelayAction } from '../types/border-cross-actions';
import {
  FETCH_BORDER_CROSSING_DELAY_UPDATES,
  FETCH_BORDER_CROSSING_DELAY_UPDATES_FAILURE,
  FETCH_BORDER_CROSSING_DELAY_UPDATES_FULFILLED,
  FETCH_BORDER_CROSSING_DELAY_UPDATES_PENDING,
  FETCH_BORDER_CROSSING_DELAY_UPDATES_REJECTED,
} from '../constants';

const initialState = {
  pending: false,
  delayUpdates: [],
  error: null,
};
export function BorderCrossingDelayReducer(
  state = initialState,
  action: BorderCrossDelayAction,
) {
  switch (action.type) {
    case FETCH_BORDER_CROSSING_DELAY_UPDATES:
      return {
        ...state,
        pending: true,
        error: null,
        delayUpdates: [],
      };
    case FETCH_BORDER_CROSSING_DELAY_UPDATES_PENDING:
      return {
        ...state,
        pending: true,
        delayUpdates: [],
        error: null,
      };
    case FETCH_BORDER_CROSSING_DELAY_UPDATES_FULFILLED:
      const borderCrossingDelayUpdates = action.payload.data || [];
      return {
        ...state,
        pending: false,
        borderCrossingDelayUpdates: borderCrossingDelayUpdates,
      };
    case FETCH_BORDER_CROSSING_DELAY_UPDATES_REJECTED:
      return {
        ...state,
        pending: false,
        delayUpdates: [],
        error: action.payload.message,
      };
    case FETCH_BORDER_CROSSING_DELAY_UPDATES_FAILURE:
      return {
        ...state,
        pending: false,
        delayUpdates: [],
        error: action.payload.message,
      };
  }
  return state;
}
