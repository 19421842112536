import { OceanPortAddressesAction } from '../types/border-cross-actions';
import {
  FETCH_OCEAN_PORT_ADDRESSES,
  FETCH_OCEAN_PORT_ADDRESSES_PENDING,
  FETCH_OCEAN_PORT_ADDRESSES_FULFILLED,
  FETCH_OCEAN_PORT_ADDRESSES_REJECTED,
  FETCH_OCEAN_PORT_ADDRESSES_FAILURE,
} from '../constants';

const initialState = {
  pending: false,
  addresses: [],
  error: null,
};
export function OceanPortAddressesReducer(
  state = initialState,
  action: OceanPortAddressesAction,
) {
  switch (action.type) {
    case FETCH_OCEAN_PORT_ADDRESSES:
      return {
        ...state,
        pending: true,
        error: null,
        addresses: [],
      };
    case FETCH_OCEAN_PORT_ADDRESSES_PENDING:
      return {
        ...state,
        pending: true,
        addresses: [],
        error: null,
      };
    case FETCH_OCEAN_PORT_ADDRESSES_FULFILLED:
      const addresses = action.payload.data.addresses || [];
      const newState = { ...state, pending: false, addresses };
      return newState;
    case FETCH_OCEAN_PORT_ADDRESSES_REJECTED:
      return {
        ...state,
        pending: false,
        addresses: [],
        error: action.payload.message,
      };
    case FETCH_OCEAN_PORT_ADDRESSES_FAILURE:
      return {
        ...state,
        pending: false,
        addresses: [],
        error: action.payload.message,
      };
  }
  return state;
}
