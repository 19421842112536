import React from 'react';
import './MapLegend.scss';
import {
  REGIONAL_INSIGHTS_COLORS,
  STATES_LOADS_RANGES,
  REGIONAL_INSIGHTS_LABELS
} from '../../../constants';
interface LegendPopoverProps {
  className: string;
  mode?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface LegendState {
  showLegend: boolean;
}

interface LegendProps {
  type?: string;
  mode?: string;
}

export default class Legend extends React.Component<LegendProps, LegendState> {
  constructor(props: any) {
    super(props);
    this.state = {
      showLegend: false,
    };
  }

  toggleClass() {
    const bool = this.state.showLegend;
    this.setState({
      showLegend: !bool,
    });
  }

  get legendClass():string {
    return this.state.showLegend ? 'show' : 'hide';
  }

  handleClick = () => {
    this.toggleClass();
  }

  render() {
    let legendPopover = null;
    switch(this.props.type) {
      case "choro":
        legendPopover = (<ChoroLegendPopover className={this.legendClass} mode={this.props.mode} onClick={this.handleClick}/>);
        break;
      case "ocean":
        legendPopover = (<OceanLegendPopover className={this.legendClass} onClick={this.handleClick}/>);
        break;
      default:
        legendPopover = <LegendPopover className={this.legendClass} onClick={this.handleClick}/>
    }
    return (
      <section>
        <div className="Legend-wrapper">
          <div
            className="circle"
            onClick={this.toggleClass.bind(this)}
            // onMouseOut={this.toggleClass.bind(this)}
          >
            <i className="far fa-question-circle"></i>
          </div>
        </div>
        {legendPopover}
      </section>
    );
  }
}

function LegendPopover(props: LegendPopoverProps) {
  return (
    <div className={`Legend-popup-wrapper ${props.className}`}>
      <i className="fa legend-close" onClick={props.onClick}>&#xf00d;</i>
      <div className="Title">Map Legend</div>
      <div className="Delay Early">
        <i className="fas fa-circle"></i>
        <span>No significant delays</span>
      </div>
      <div className="Delay On-time">
        <i className="fas fa-circle"></i>
        <span>Delayed 15 min - 1 hour</span>
      </div>
      <div className="Delay Late">
        <i className="fas fa-circle"></i>
        <span>Delayed &ge; 1 hour</span>
      </div>
      <div className="Delay Very-late">
        <i className="fas fa-circle"></i>
        <span>Delayed &ge; 1 day</span>
      </div>
      <div className="Delay No-data">
        <i className="fas fa-circle"></i>
        <span>No recent data available</span>
      </div>
      <div className="About">About this Map</div>
      <div className="Desc">
        The times on the map are based on all the cross-border loads in the
        Fourkites network, over the past 24 hours
      </div>
    </div>
  );
}

function OceanLegendPopover(props: LegendPopoverProps) {
  return (
    <div className={`Legend-popup-wrapper ${props.className}`}>
      <i className="fa legend-close" onClick={props.onClick}>&#xf00d;</i>
      <div className="Title">Map Legend</div>
      <div className="Delay Early">
        <i className="fas fa-circle"></i>
        <span>No significant delays</span>
      </div>
      <div className="Delay On-time">
        <i className="fas fa-circle"></i>
        <span>Delayed &ge; 25%</span>
      </div>
      <div className="Delay Late">
        <i className="fas fa-circle"></i>
        <span>Delayed &ge; 50%</span>
      </div>
      <div className="Delay Very-late">
        <i className="fas fa-circle"></i>
        <span>Delayed &ge; 75%</span>
      </div>
      <div className="Delay No-data">
        <i className="fas fa-circle"></i>
        <span>No recent data available</span>
      </div>
      <div className="Note">
        <small>
          *The percentages are percentage increase in delay with respect to the
          average delay at port and anchorage.
        </small>
      </div><br></br>
      <div className="About">Waiting for Berth</div>
      <div className="Desc">
        Time spent by the vessel at anchorage waiting for a berth to become
        available.
      </div>
      <div className="About">Waiting at Port</div>
      <div className="Desc">
        Overall time spent by the vessel in the terminal.
      </div>
      <div className="About">Import Dwell</div>
      <div className="Desc">
      Time spent by container at Port of Discharge from unloading to gate-out.
      </div>
      <div className="About">Export Dwell</div>
      <div className="Desc">
      Time spent by container at Port of Loading from gate-in to loading.
      </div>
      <div className="About">About this Map</div>
      <div className="Desc">
        The congestion times on this map are based on all the port congestion delays
        over the past 1 week and the Dwell times are based on all the dwell times over the past 1 month
        for the loads in Fourkites network.{' '}
      </div>
    </div>
  );
}

function ChoroLegendPopover(props: LegendPopoverProps) {
  const increaseColors = ["#B4D9B5", "#7BBD7E", "#539257", "#4F7752"];
  const ranges = ["<5%", "6-10%", "11-15%", ">=15%"];
  const dropColors = ["#FEE5D9", "#FC9274", "#E24E48", "#B70743"];
  const renderRange = (range: string) => (<div key={range} className={'label'}>{range}</div>);
  const renderColor = (backgroundColor: string) => (<div key={backgroundColor} className={'color-box'} style={{ backgroundColor }}></div>);
  return (
    <div className={`Legend-popup-wrapper ${props.className}`}>
      <i className="fa legend-close" onClick={props.onClick}>&#xf00d;</i>
      <div className="Title">Map Legend</div>
      <div className="group">
        <div className="group-title">Truck Movement Increase by %</div>
        {increaseColors.map((color) => renderColor(color))}
        {ranges.map((range) => renderRange(range))}
      </div>
      <div className="group">
        <div className="group-title">Truck Movement Drop by %</div>
        {dropColors.map((color) => renderColor(color))}
        {ranges.map((range) => renderRange(range))}
      </div>
      <div className="Desc">
        The data shows a relative change of this week’s average truck movement and dwell time compared to the average of the previous two weeks. Here is how to interpret this data.
      </div>
      <div className="Title">About this map</div>
      <div className="Desc">
        This info on this map are based on all the load traffic in the Fourkites network, over the past 24 hours.
      </div>
      <div className="About">Inbound Volume</div>
      <ul className="Desc">
        <li>Inbound increase could indicate healthy demand.</li>
        <li>Inbound decrease could indicate problem in supply, demand or truck availability.</li>
      </ul>
      <div className="About">Outbound Volume</div>
      <ul className="Desc">
        <li>Outbound increase could indicate healthy industrial production.</li>
        <li>Outbound decrease could indicate problem in supply, demand or truck availability.</li>
      </ul>
      <div className="About">Dwell Time</div>
      <ul className="Desc">
        <li>Truck Movement Increase, Dwell Time Increase could mean the facilities are getting congested serving more than usual trucks.</li>
        <li>Truck Movement Decrease, Dwell Time Increase could mean there are operational issues or labor shortages in the facilities.</li>
        <li>Truck Movement Increase, Dwell Time Decrease could mean the facilities are proactively serving the customers inspite of increase in truck movements.</li>
        <li>Truck Movement Decrease, Dwell Time Decrease could mean the facilities are less congested than usual and so are serving the trucks faster.</li>
      </ul>
    </div>
  );
}
