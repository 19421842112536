import React, { useState } from 'react';
import './CityInsights.scss';
import { CityProperties } from '../../../types/choropleth-types';
import DataTable from 'react-data-table-component';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

type CityInsightsProps = {
    cityUpdates: CityProperties;
    currentMode: string
};

const CityInsights = (props: CityInsightsProps): JSX.Element => { 
    function checkDisplayModeIsInbound(displayMode: string) {
        return displayMode === 'inbound';
    }

    function getVectorClass(field: any, labelStyle: any) {
        if (isNaN(field)) {
            return 'No data available';
        }
        if (field < 0) {
            field = Math.abs(field);
            return (
                <span><i style={labelStyle} className="far fa-long-arrow-down"></i>{field}%</span>
            );
        }
        return (
            <span><i style={labelStyle} className="far fa-long-arrow-up"></i>{field}%</span >
        );
    }

    function getLabelStyle(field: number) {
        let color = "#8F8F8F";
        switch (field) {
            case 1:
            case 2:
            case 3:
                color = "#539257"
                break
            case 4:
                color = "#4f7752"
                break
            case -1:
            case -2:
            case -3:
                color = "#E24E48"
                break
            case -4:
                color = "#B74043"
                break
        }
        return color
    }

    function getColumns() {
        const displayMode = props.currentMode;
        const columns = [{
            name: 'Rank',
            selector: 'rank',
            sortable: false,
            width: '60px',
            compact: true,
            style: {
                position: 'relative',
                left: '10px'
            }
        },
        {
            name: 'City',
            selector: 'name',
            sortable: false,
            compact: true
        },
        {
            name: checkDisplayModeIsInbound(displayMode) ? 'Inbound' : 'Outbound',
            sortable: false,
            center: true,
            compact: true,
            cell: (row: any) => {
                let loadData = row.change;
                let dataColor = row.changeColor;
                loadData = parseFloat(loadData);
                const loadTextstyle = {
                    color: getLabelStyle(parseFloat(dataColor)),
                    marginRight: '5px',
                    verticalAlign: 'text-top'
                };
                return getVectorClass(loadData, loadTextstyle);
            },
        },
        {
            name: 'Dwell time',
            sortable: false,
            center: true,
            compact: true,
            cell: (row: any) => {
                let dwellData = row.dwellChange;
                let dataColor = row.dwellChangeColor
                dwellData = parseFloat(dwellData);
                const dwellTextstyle = {
                    color: getLabelStyle(parseFloat(dataColor)),
                    marginRight: '3px',
                    verticalAlign: 'middle'
                };
                return getVectorClass(dwellData, dwellTextstyle);
            },
        }];
        return columns;
    }

    function renderCityInsights() {
        const data = checkDisplayModeIsInbound(props.currentMode) ? props.cityUpdates['inbound'] : props.cityUpdates['outbound'];
        const striped = true, fixedHeader = true, responsive = true, dense = true;
        const hasData = (data && data.length !== 0);
        if (hasData) {
            const columns = getColumns();
            return (
                <div className="city-info-wrapper">
                    <Tabs className="insights-tabs">
                        <TabList>
                            <Tab>Cities</Tab>
                        </TabList>
                        <TabPanel>
                            <DataTable
                                columns={columns}
                                data={data}
                                responsive={responsive}
                                striped={striped}
                                fixedHeader={fixedHeader}
                                fixedHeaderScrollHeight="250px"
                                dense={dense}
                                className="city-insights-datatable"
                            />
                        </TabPanel>
                    </Tabs>
                </div>
            );
        }
    }
    return (
        <div className="choro-item city-info-panel">
            {renderCityInsights()}
        </div>
    );
}

export default CityInsights;