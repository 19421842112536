import React from 'react';
import BorderEventUpdates from '../BorderEventUpdates/BorderEventUpdates';
import MapElement from '../../../common/components/MapElement/MapElement';
import './BorderCrossing.scss';
import { connect } from 'react-redux';
import {
  fetchingBorderCrossingEventsUpdates,
  fetchingBorderCrossingDelayUpdates,
  syncLiveBorderCrossingDelay,
} from '../../../actions';
import { bindActionCreators } from 'redux';
import {
  BorderEventUpdate,
  BorderCrossDelay,
} from '../../../types/border-cross-types';

const LONG_POLLING_INTERVAL = 600000;

type BorderCrossState = {
  eventsUpdates: BorderEventUpdate[];
  delayUpdates: BorderCrossDelay[];
  isDelayPending: boolean;
  isEventsPending: boolean;
  isDelayError: boolean;
  isEventsError: boolean;
  activeMode: string;
};

type StateProps = {
  eventsUpdates: BorderEventUpdate[];
  delayUpdates: BorderCrossDelay[];
  isDelayPending: boolean;
  isEventsPending: boolean;
  isDelayError: boolean;
  isEventsError: boolean;
  activeMode: string;
};

type DispatchProps = {
  fetchingBorderCrossingEventsUpdates: Function;
  fetchingBorderCrossingDelayUpdates: Function;
  syncLiveBorderCrossingDelay: Function;
};

type BorderCrossProps = StateProps & DispatchProps;

class BorderCrossing extends React.Component<
  BorderCrossProps,
  BorderCrossState
> {
  public longInterval: NodeJS.Timeout | null = null;
  constructor(props: BorderCrossProps) {
    super(props);
    this.state = {
      eventsUpdates: [],
      delayUpdates: [],
      isDelayPending: false,
      isEventsPending: false,
      isDelayError: false,
      isEventsError: false,
      activeMode: '',
    };
  }

  componentDidMount(): void {
    this.props.fetchingBorderCrossingEventsUpdates();
    this.props.fetchingBorderCrossingDelayUpdates();
    //LONG POLLING
    this.longPollingUpdates();
  }

  longPollingUpdates(): void {
    this.longInterval = setInterval(() => {
      this.props.fetchingBorderCrossingDelayUpdates();
    }, LONG_POLLING_INTERVAL);
  }

  componentWillUnmount(): void {
    this.clearLongPolling();
  }

  clearLongPolling(): void {
    if (this.longInterval) {
      clearInterval(this.longInterval);
    }
  }

  componentDidUpdate(prevProps: Readonly<BorderCrossProps>): void {
    const {
      eventsUpdates,
      isEventsPending,
      isEventsError,
      delayUpdates,
      isDelayPending,
      isDelayError,
      syncLiveBorderCrossingDelay,
    } = this.props;
    if (prevProps.eventsUpdates !== eventsUpdates) {
      this.setState({
        eventsUpdates: eventsUpdates,
      });
    }
    if (prevProps.isEventsPending !== isEventsPending) {
      this.setState({
        isEventsPending: isEventsPending,
      });
    }
    if (prevProps.isEventsError !== isEventsError) {
      this.setState({
        isEventsError: isEventsError,
      });
    }
    if (prevProps.delayUpdates !== delayUpdates) {
      this.setState({
        delayUpdates: delayUpdates,
      });
    }
    if (prevProps.isDelayPending !== isDelayPending) {
      this.setState({
        isDelayPending: isDelayPending,
      });
    }
    if (prevProps.isDelayError !== isDelayError) {
      this.setState({
        isDelayError: isDelayError,
      });
    }
    if (!isDelayPending && !isDelayError) {
      syncLiveBorderCrossingDelay();
      this.clearLongPolling();
      this.longPollingUpdates();
    }
  }

  render(): JSX.Element {
    return (
      <div className="map-wrapper" data-testid="border-crossing-component">
        <BorderEventUpdates
          borderCrossingEventsUpdates={this.state.eventsUpdates}
        />
        <MapElement
          error={this.state.isDelayError}
          pending={this.state.isDelayPending}
          borderCrossingDelayUpdates={this.state.delayUpdates}
        />
      </div>
    );
  }
}

function mapStateToProps(state: any): StateProps {
  return {
    eventsUpdates: state.eventsUpdates.borderCrossingEventsUpdates,
    isEventsPending: state.eventsUpdates.pending,
    isEventsError: state.eventsUpdates.error,
    delayUpdates: state.delayUpdates.borderCrossingDelayUpdates,
    isDelayPending: state.delayUpdates.pending,
    isDelayError: state.delayUpdates.error,
    activeMode: state.activeMode.activeMode,
  };
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return bindActionCreators(
    {
      fetchingBorderCrossingEventsUpdates,
      fetchingBorderCrossingDelayUpdates,
      syncLiveBorderCrossingDelay,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(BorderCrossing);
