export const FETCH_BORDER_CROSSING_EVENTS_UPDATES =
  'FETCH_BORDER_CROSSING_EVENT_UPDATES';

export type FETCH_BORDER_CROSSING_EVENT_UPDATES = typeof FETCH_BORDER_CROSSING_EVENTS_UPDATES;

export const FETCH_BORDER_CROSSING_DELAY_UPDATES =
  'FETCH_BORDER_CROSSING_DELAY_UPDATES';

export type FETCH_BORDER_CROSSING_DELAY_UPDATES = typeof FETCH_BORDER_CROSSING_DELAY_UPDATES;

export const FETCH_BORDER_CROSSING_DELAY_UPDATES_FULFILLED =
  'FETCH_BORDER_CROSSING_DELAY_UPDATES_FULFILLED';

export const FETCH_BORDER_CROSSING_DELAY_UPDATES_FAILURE =
  'FETCH_BORDER_CROSSING_DELAY_UPDATES_FAILURE';

export const FETCH_BORDER_CROSSING_DELAY_UPDATES_REJECTED =
  'FETCH_BORDER_CROSSING_DELAY_UPDATES_REJECTED';

export const FETCH_BORDER_CROSSING_DELAY_UPDATES_PENDING =
  'FETCH_BORDER_CROSSING_DELAY_UPDATES_PENDING';

export const FETCH_REGIONAL_DELAY_UPDATES = 'FETCH_REGIONAL_DELAY_UPDATES';

export type FETCH_REGIONAL_DELAY_UPDATES = typeof FETCH_REGIONAL_DELAY_UPDATES;

export const FETCH_REGIONAL_DELAY_UPDATES_FULFILLED =
  'FETCH_REGIONAL_DELAY_UPDATES_FULFILLED';

export const FETCH_REGIONAL_DELAY_UPDATES_FAILURE =
  'FETCH_REGIONAL_DELAY_UPDATES_FAILURE';

export const FETCH_REGIONAL_DELAY_UPDATES_REJECTED =
  'FETCH_REGIONAL_DELAY_UPDATES_REJECTED';

export const FETCH_REGIONAL_DELAY_UPDATES_PENDING =
  'FETCH_REGIONAL_DELAY_UPDATES_PENDING';

export const FETCH_REGIONAL_CITY_DELAY_UPDATES = 'FETCH_REGIONAL_CITY_DELAY_UPDATES';

export type FETCH_REGIONAL_CITY_DELAY_UPDATES = typeof FETCH_REGIONAL_CITY_DELAY_UPDATES;

export const FETCH_REGIONAL_CITY_DELAY_UPDATES_FULFILLED =
  'FETCH_REGIONAL_CITY_DELAY_UPDATES_FULFILLED';

export const FETCH_REGIONAL_CITY_DELAY_UPDATES_FAILURE =
  'FETCH_REGIONAL_CITY_DELAY_UPDATES_FAILURE';

export const FETCH_REGIONAL_CITY_DELAY_UPDATES_REJECTED =
  'FETCH_REGIONAL_CITY_DELAY_UPDATES_REJECTED';

export const FETCH_REGIONAL_CITY_DELAY_UPDATES_PENDING =
  'FETCH_REGIONAL_CITY_DELAY_UPDATES_PENDING';

export const FETCH_CITY_INSIGHTS = 'FETCH_CITY_INSIGHTS';

export type FETCH_CITY_INSIGHTS = typeof FETCH_CITY_INSIGHTS;

export const FETCH_CITY_INSIGHTS_FULFILLED = 'FETCH_CITY_INSIGHTS_FULFILLED';

export const FETCH_CITY_INSIGHTS_FAILURE = 'FETCH_CITY_INSIGHTS_FAILURE';

export const FETCH_CITY_INSIGHTS_REJECTED = 'FETCH_CITY_INSIGHTS_REJECTED';

export const FETCH_CITY_INSIGHTS_PENDING = 'FETCH_CITY_INSIGHTS_PENDING';

export const CHANGE_REGIONAL_DISPLAY_MODE = 'CHANGE_REGIONAL_DISPLAY_MODE';

export const SELECTED_MODE = 'SELECTED_MODE';

export enum MODES {
  PORT_CONGESTION = 'PORT_CONGESTION',
  BORDER_CROSSING = 'BORDER_CROSSING',
  REGIONAL_INSIGHTS = 'REGIONAL_INSIGHTS',
}

export const FETCH_OCEAN_PORT_ADDRESSES = 'FETCH_OCEAN_PORT_ADDRESSES';
export type FETCH_OCEAN_PORT_ADDRESSES = typeof FETCH_OCEAN_PORT_ADDRESSES;
export const FETCH_OCEAN_PORT_ADDRESSES_PENDING =
  'FETCH_OCEAN_PORT_ADDRESSES_PENDING';
export const FETCH_OCEAN_PORT_ADDRESSES_FULFILLED =
  'FETCH_OCEAN_PORT_ADDRESSES_FULFILLED';
export const FETCH_OCEAN_PORT_ADDRESSES_REJECTED =
  'FETCH_OCEAN_PORT_ADDRESSES_REJECTED';
export const FETCH_OCEAN_PORT_ADDRESSES_FAILURE =
  'FETCH_OCEAN_PORT_ADDRESSES_FAILURE';

export const ROUTE_BORDER_CROSSING = `border-crossing`;
export const ROUTE_PORT_CONGESTION = `port-congestion`;
export const ROUTE_REGIONAL_INSIGHTS = `regional-insights`;

export const TITLE_BORDER_CROSSING = `CURRENT BORDER CROSSING TRANSIT TIMES`;
export const TITLE_PORT_CONGESTION = `PORT CONGESTION TIMES FOR CONTAINER VESSELS`;
export const TITLE_REGIONAL_INSIGHTS = ``;

export const SYNC_LIVE_TIME = 'SYNC_LIVE_TIME';

export const SYNC_LIVE_TIME_UPDATES = 'SYNC_LIVE_TIME_UPDATES';

export const LONG_POLLING_INTERVAL = 600000;

export enum ENUM_BORDER_DELAY_COLOR_CODES {
  VERY_LATE = '#B71C1C',
  LATE = '#FF6F00',
  ON_TIME = '#FEA53F',
  EARLY = '#26C572',
  NO_ETA = '#9E9E9E',
}
export const BORDER_DELAY_COLOR_CODES = [
  { key: 'VERY_LATE', value: '#B71C1C' },
  { key: 'LATE', value: '#FF6F00' },
  { key: 'ON_TIME', value: '#FEA53F' },
  { key: 'EARLY', value: '#26C572' },
  { key: 'NO_ETA', value: '#9E9E9E' },
];
const INBOUND_COLORS = ['#9E9E9E', '#A6D7F5', '#6CC0EF', '#3FABEC', '#0087DF', '#0067D0'];
const OUTBOUND_COLORS = ['#9E9E9E', '#FDD5C3', '#FCA488', '#FB7354', '#EA5042', '#C23034'];
export const STATES_LOADS_RANGES = ['No Data','< 200', '200-500', '500-1000', '1000-2000', '> 2000'];
export const CITIES_LOADS_RANGES = ['No Data','< 200', '200-500', '500-1000', '1000-2000', '> 2000'];
export const REGIONAL_INSIGHTS_LABELS: {[key:string]:string} = {
  'inbound': "Inbound",
  'outbound': "Outbound",
  'emptyMiles': "Empty Miles",
  'dwell_time': "Dwell Time"
};
// export const REGIONAL_INSIGHTS_COLORS: {[key:string]:string[]} = {
//   'inbound': INBOUND_COLORS,
//   'outbound': OUTBOUND_COLORS,
// }
export const REGIONAL_INSIGHTS_COLORS: {[key:string]:string} = {
  "-4": "#B74043",
  "-3": "#E24E48",
  "-2": "#FC9274",
  "-1": "#FEE5D0",
  "0": "#DEDEDE",
  "1": "#B4D9B5",
  "2": "#7bbd7e",
  "3": "#539257",
  "4": "#4f7752",
};

export const REGIONAL_INSIGHTS_MAP_BORDER_COLORS: {[key:string]:string} = {
  "-4": "#8a2f32",
  "-3": "#ab3b37",
  "-2": "#c7735b",
  "-1": "#ffc494",
  "0": "#8a8a8a",
  "1": "#8aa68b",
  "2": "#4e7a51",
  "3": "#366638",
  "4": "#2e4730",
};

export const COLORS: string[] = ["#bada55", "#990000", "#5ac18e", "#003366", "#ff6666", "#196db6", "#ff4040", "#9400D3", "#008080"];
export const REJECT_INDUSTRIES = ["Broker", "Carrier", "3PL", "Software", "Consulting"];
export const INDUSTRY_COLORS: {[key:string]:string} = {
  "Other": "#DEDEDE", 
  "Oil & Gas": "#660066",
  "Food Distribution": "#ff7f50",
  "Chemical & Plastic": "#ccff00",
  "Manufacturing": "#daa520",
  "CPG": "#66cdaa",
  "Food & Beverage": "#ff0000",
  "Pharmaceutical": "#f7347a",
  "Automotive": "#00ced1",
  "Paper & Packaging": "#4ca3dd",
  "Construction": "#407294",
  "Retail": "#ffd700"
}
