import React from 'react';
import ToggleOption from 'common/components/ToggleOption/ToggleOption';
import './DisplaySelector.scss';

type DisplaySelectorProps = {
  changeDisplayMode: Function;
  className: string;
  currentMode?: string;
};

const DisplaySelector = (props: DisplaySelectorProps): JSX.Element => {
  const isActive = (mode: string) => (mode===props.currentMode);
  return (
    <section className="switchButton modeSwitcher">
      <div className="Button-wrapper">
        <ToggleOption
          mode={'inbound'}
          active={isActive('inbound')}
          selectMode={(): void => {
            props.changeDisplayMode('inbound');
          }}
        />
        <ToggleOption
          mode={'outbound'}
          active={isActive('outbound')}
          selectMode={(): void => {
            props.changeDisplayMode('outbound');
          }}
        />
      </div>
    </section>
  );
};

export default DisplaySelector;
