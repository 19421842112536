import { CityInsightsAction } from '../types/border-cross-actions';
import {
  FETCH_CITY_INSIGHTS,
  FETCH_CITY_INSIGHTS_FAILURE,
  FETCH_CITY_INSIGHTS_FULFILLED,
  FETCH_CITY_INSIGHTS_PENDING,
  FETCH_CITY_INSIGHTS_REJECTED,
} from '../constants';
import { changeKeys } from './translators/regionalDelayUpdatesTranslator';
import { CityProperties } from 'types/choropleth-types';

const initialState = {
  pending: false,
  data: {
    inbound: [],
    outbound: []
  } as CityProperties,
  error: null
};
export function CityInsightsReducer(
  state = initialState,
  action: CityInsightsAction,
): any {
  switch (action.type) {
    case FETCH_CITY_INSIGHTS:
      return {
        ...state,
        pending: true,
        error: null,
        data: {
          inbound: [],
          outbound: []
        } as CityProperties,
      };
    case FETCH_CITY_INSIGHTS_PENDING:
      return {
        ...state,
        pending: true,
        data: {
          inbound: [],
          outbound: []
        } as CityProperties,
        error: null,
      };
    case FETCH_CITY_INSIGHTS_FULFILLED:
      if (action.payload.data['inbound']) {
        action.payload.data['inbound'].forEach((inboundData: any, index: number) => {
          inboundData = changeKeys(inboundData);
          inboundData.rank = index + 1;
        });
      }
      
      if (action.payload.data['outbound']) {
        action.payload.data['outbound'].forEach((outboundData: any, index: number) => {
          outboundData = changeKeys(outboundData);
          outboundData.rank = index + 1;
        });
      }

      const cityInsights = action.payload.data || {};
      return {
        ...state,
        pending: false,
        data: cityInsights,
      };
    case FETCH_CITY_INSIGHTS_REJECTED:
      return {
        ...state,
        pending: false,
        data: {
          inbound: [],
          outbound: []
        } as CityProperties,
        error: action.payload.message,
      };
    case FETCH_CITY_INSIGHTS_FAILURE:
      return {
        ...state,
        pending: false,
        data: {
          inbound: [],
          outbound: []
        } as CityProperties,
        error: action.payload.message,
      };
  }
  return state;
}
