import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useHistory } from "react-router-dom";
import * as _ from 'lodash';
import {
  Map,
  TileLayer,
  LayersControl,
  ZoomControl,
  GeoJSON,
  Tooltip,
} from 'react-leaflet';
import { FeatureCollection } from 'geojson';
import Legend from '../MapElement/MapLegend';
import Explore from '../MapElement/MapExplore';
import { mapDefaults } from '../mapDefaults/mapDefaults';
import LayerSwitcher from '../MapElement/MapLayerSwitcher';
import {
  Coordinates,
  Bounds,
  RegionProperties,
} from '../../../types/choropleth-types';
import leaflet, { PathOptions } from 'leaflet';
import ChoroplethTooltip from './ChoroplethTooltip';
import { REGIONAL_INSIGHTS_COLORS, STATES_LOADS_RANGES, ROUTE_REGIONAL_INSIGHTS, REGIONAL_INSIGHTS_MAP_BORDER_COLORS } from '../../../constants';
import './Choropleth.scss';
import { GENERAL_UTIL } from '../../../utils/general-util';
import {
  LayerControls,
} from '../../constants/map-config.constants';

type ChoroplethProps = {
  bounds: Bounds;
  regions: FeatureCollection;
  displayMode: string;
};

const Choropleth = (props: ChoroplethProps): JSX.Element => {
  const history = useHistory();
  const mapRef: any = React.createRef();
  let setMaxBounds = setTimeout(() => {
    let mapInst = mapRef && mapRef.current && mapRef.current.leafletElement;
    if(mapInst){
      const minZoom = isMobile() ? 3 : 4;
      mapInst.setMinZoom(minZoom);
      const maxBounds = (isMobile() ? mapInst.getBounds() : leaflet.geoJSON(props.regions).getBounds()).pad(0.25);
      mapInst.setMaxBounds(maxBounds);
      clearTimeout(setMaxBounds);
    }
  }, 100);

  const {
    latitude: upperLeftLat,
    longitude: upperLeftLong,
  }: Coordinates = props.bounds.upperLeft;

  const {
    latitude: bottomRightLat,
    longitude: bottomRightLong,
  }: Coordinates = props.bounds.bottomRight;

  const modeObj = GENERAL_UTIL.getThemeObj();

  const [state, setState] = useState({
    mode: modeObj.className,
    modeValue: modeObj.value,
  });

  useEffect(() => {
    setTimeout(() => {
      if (props.regions) {
        if (
          mapRef &&
          mapRef.current &&
          mapRef.current.leafletElement
        ) mapRef.current.leafletElement.invalidateSize();
        removeEventListener();
        if (
          mapRef &&
          mapRef.current &&
          mapRef.current.leafletElement
        ) mapRef.current.leafletElement.on('baselayerchange', (e: any) => {
          let mode;
          let modeValue;
          if (e.name === 'dark') {
            mode = LayerControls[1].className;
            modeValue = LayerControls[1].value;
          } else {
            mode = LayerControls[0].className;
            modeValue = LayerControls[0].value;
          }
          GENERAL_UTIL.storeTheme(modeValue);
          setState({
            mode,
            modeValue,
          });
        });
      }
    });
  })

  function removeEventListener() {
    if (
      mapRef &&
      mapRef.current &&
      mapRef.current.leafletElement
    ) {
      mapRef.current.leafletElement.off('baselayerchange');
    }
  }

  function createTooltipContent(feature: any): string {
    return ReactDOMServer.renderToString(
      <ChoroplethTooltip
        className={'choropleth-tooltip'}
        name={feature.properties.name}
        regionDelay={feature.properties.delay}
        displayMode={props.displayMode}
        colorKey={getColorIndex(feature)}
      />,
    );
  }

  function getHighlightStyle(feature: any): any {
    return {
      weight: 5,
      color: '#FEFEFE',
      dashArray: '',
      fillOpacity: 1,
    };
  }

  function isMobile() {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];
    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
  }

  function onRegionHover(event: any): void {
    const layer = event.target;
    const feature = layer.feature;
    if (layer) {
      !isMobile() && layer.setStyle(getHighlightStyle(feature));
      if (
        !leaflet.Browser.ie &&
        !leaflet.Browser.opera &&
        !leaflet.Browser.edge
      ) {
        layer.bringToFront();
      }
      const popupContent = createTooltipContent(feature);
      layer._tooltip.setContent(popupContent);
    }
    // event.type === "click" && !isMobile() && zoomToRegion(event);
  }

  function getColorIndex(feature: any): string {
    const colorKey =  props.displayMode + 'Color'
    return feature.properties &&
      feature.properties.delay && feature.properties.delay[colorKey] ? feature.properties.delay[colorKey] : "0";
  }
  
  function getStateColor(feature: any, colors: {[key:string]:string}): string {
    const colorIndex = getColorIndex(feature);
    if(colors[colorIndex]){
      return colors[colorIndex];
    }
    return parseInt(colorIndex) > 0 ? colors["4"] : colors["-4"];
  }

  function styleRegion(feature: any): PathOptions {
    let pathOptions: PathOptions = {
      fillColor: getStateColor(feature, REGIONAL_INSIGHTS_COLORS),
      weight: 2,
      opacity: 1,
      color: 'white',
      dashArray: '3',
      fillOpacity: 1,
    };
    if(feature.properties.id == history.location.pathname.replace(/\/regional-insights\//, '')){
      pathOptions = _.assign(pathOptions, getHighlightStyle(feature));
    }
    return pathOptions;
  }

  function onRegionClick(event: any): void {
    const layer = event.target;
    const feature = layer.feature;
    const properties = feature.properties;
    const id = properties.id;
    const color = parseInt(properties.delay[`${props.displayMode}Color`]);
    const hasData = isNaN(color) || !color ? false : true;
    const map = event.target.options.leaflet.map;
    console.log(properties, color, hasData);
    if(id && hasData){
      map.panTo(layer.getCenter());
      history.push(`/${ROUTE_REGIONAL_INSIGHTS}/${id}`);
    }
  }

  function resetRegionStyle(event: any): void {
    const layer = event.target;
    const region = layer.feature;
    const originalStyle = styleRegion(region);
    layer.setStyle(originalStyle);
  }

  function onEachRegion(feature: any, layer: any): void {
    const layerBehaviour = isMobile() ? {
      click: onRegionHover
    } : {
      mouseover: onRegionHover,
      mouseout: resetRegionStyle,
      click: onRegionClick,
    }
    layer.on(layerBehaviour);
    layer.bindTooltip(<Tooltip> </Tooltip>,{
      direction:"top",
      offset: [0, -20],
      opacity: 1
    });   
    let hasCode = setTimeout(() => {
      if(feature.properties && feature.properties.code){
        const icon = new leaflet.DivIcon({
          html: feature.properties.code,
          iconSize: [24, 8],
          iconAnchor: [0, 0],
          className: 'region-label'
        });
        const point = layer.getCenter();
        const marker = new leaflet.Marker(point, {
          icon,
          interactive: false,
        });
        marker.addTo(layer.options.leaflet.map);
        clearTimeout(hasCode);
      }
    }, 1000);
  }

  return (
    <div className={`choro-item Map-updates ${state.mode}`}>
      <Map
        ref={mapRef}
        scrollWheelZoom={mapDefaults.scrollWheelZoom}
        maxZoom={mapDefaults.maxZoom}
        minZoom={mapDefaults.minZoom}
        zoom={mapDefaults.zoom}
        zoomControl={mapDefaults.zoomControl}
        animate={mapDefaults.animate}
        bounds={[
          [upperLeftLat, upperLeftLong],
          [bottomRightLat, bottomRightLong],
        ]}
      >
        <LayersControl position="topright">
          <LayersControl.BaseLayer name="light" checked={true}>
            <TileLayer
              attribution={mapDefaults.lightTileLayer.attribution}
              url={mapDefaults.lightTileLayer.tile}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="dark">
            <TileLayer
              attribution={mapDefaults.darkTileLayer.attribution}
              url={mapDefaults.darkTileLayer.tile}
            />
          </LayersControl.BaseLayer>
        </LayersControl>
        <GeoJSON
          data={props.regions}
          key={props.displayMode}
          style={styleRegion}
          onEachFeature={onEachRegion}
        />
        <ZoomControl position="topright" />
        <LayerSwitcher mapElement={mapRef} mode={state.modeValue} />
      </Map>
      <Legend type={"choro"} mode={props.displayMode}></Legend>
      <Explore></Explore>
    </div>
  );
};

export default Choropleth;
