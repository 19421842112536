import React from 'react';
import { LayerControls } from '../../constants/map-config.constants';

interface LayerSwitcherProps {
  mapElement: any;
  mode: string;
}

interface LayerSwitcherState {
  activeLayer: string;
}

class LayerSwitcher extends React.Component<
  LayerSwitcherProps,
  LayerSwitcherState
> {
  public layers = LayerControls;
  constructor(props: LayerSwitcherProps) {
    super(props);
    this.state = {
      activeLayer: props.mode,
    };
  }

  componentDidMount() {
    this.toggleMode(this.props.mode, true);
  }

  trigggerCheckBoxEvent(activeLayer: string) {
    const elements: any = document.querySelectorAll(
      `[name^='leaflet-base-layers']`,
    );
    if (activeLayer === 'light') {
      elements[0].checked = true;
      elements[0].click();
    } else if (activeLayer === 'dark') {
      elements[1].checked = true;
      elements[1].click();
    }
  }

  toggleMode(activeLayer: string, init = false) {
    if (init || this.state.activeLayer !== activeLayer) {
      this.trigggerCheckBoxEvent(activeLayer);
      this.setState({
        activeLayer,
      });
    }
  }

  renderLayerControl() {
    return this.layers.map((l: any, i: number) => (
      <div
        className={`${l.className} ${
          this.state.activeLayer === l.value ? 'Active' : ''
        }`}
        key={i}
      >
        <button onClick={() => this.toggleMode(l.value)}>{l.key}</button>
      </div>
    ));
  }

  render() {
    return (
      <div className="Layer-switcher-wrapper">{this.renderLayerControl()}</div>
    );
  }
}

export default LayerSwitcher;
