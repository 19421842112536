import { RegionalCityDelayAction } from '../types/border-cross-actions';
import {
  FETCH_REGIONAL_CITY_DELAY_UPDATES,
  FETCH_REGIONAL_CITY_DELAY_UPDATES_FAILURE,
  FETCH_REGIONAL_CITY_DELAY_UPDATES_FULFILLED,
  FETCH_REGIONAL_CITY_DELAY_UPDATES_PENDING,
  FETCH_REGIONAL_CITY_DELAY_UPDATES_REJECTED,
} from '../constants';
import { changeKeys } from './translators/regionalDelayUpdatesTranslator';

const initialState = {
  pending: false,
  data: { cities:[], industries:[] },
  error: null
};
export function RegionalCityDelayReducer(
  state = initialState,
  action: RegionalCityDelayAction,
): any {
  switch (action.type) {
    case FETCH_REGIONAL_CITY_DELAY_UPDATES:
      return {
        ...state,
        pending: true,
        error: null,
        data: {},
      };
    case FETCH_REGIONAL_CITY_DELAY_UPDATES_PENDING:
      return {
        ...state,
        pending: true,
        data: {},
        error: null,
      };
    case FETCH_REGIONAL_CITY_DELAY_UPDATES_FULFILLED:
      action.payload.data.state.delay = changeKeys(action.payload.data.state.delay);
      const regionalCityDelayUpdates = action.payload.data || { state:{}, trends:{} };
      return {
        ...state,
        pending: false,
        data: regionalCityDelayUpdates,
      };
    case FETCH_REGIONAL_CITY_DELAY_UPDATES_REJECTED:
      return {
        ...state,
        pending: false,
        data: { state:{}, trends:{} },
        error: action.payload.message,
      };
    case FETCH_REGIONAL_CITY_DELAY_UPDATES_FAILURE:
      return {
        ...state,
        pending: false,
        data: { state:{}, trends:{} },
        error: action.payload.message,
      };
  }
  return state;
}
