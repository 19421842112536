import {
  FETCH_BORDER_CROSSING_EVENTS_UPDATES,
  FETCH_BORDER_CROSSING_DELAY_UPDATES,
  SYNC_LIVE_TIME_UPDATES,
  FETCH_OCEAN_PORT_ADDRESSES,
  FETCH_REGIONAL_DELAY_UPDATES,
  FETCH_REGIONAL_CITY_DELAY_UPDATES,
  SELECTED_MODE,
  CHANGE_REGIONAL_DISPLAY_MODE,
  FETCH_CITY_INSIGHTS
} from '../constants';
import {
  BorderCrossEventAction,
  BorderCrossDelayAction,
  OceanPortAddressesAction,
  RegionalDelayAction,
  RegionalCityDelayAction,
  CityInsightsAction
} from '../types/border-cross-actions';
import axios from 'axios';

const ENVIRONMENT = process.env.REACT_APP_MARKETTING_API_HOST;
const GLOBAL_ADDRESS_API_HOST = process.env.REACT_APP_GLOBAL_ADDRESS_API_HOST;
let BORDER_CROSS_DELAY_API_ENDPOINT = `${ENVIRONMENT}/border-cross-delay/wait-time`;
let OCEAN_PORT_CONGESTION_DETAILS_ENDPOINT = `${GLOBAL_ADDRESS_API_HOST}/api/v1/global_address`;
let REGIONAL_DELAY_UPDATES_ENDPOINT = `${ENVIRONMENT}/region-cross-delay/regions`;
let REGIONAL_CITY_DELAY_UPDATES_ENDPOINT = `${ENVIRONMENT}/region-cross-trends/delay/:id/state`
let REGIONAL_CITY_INSIGHTS_ENDPOINT = `${ENVIRONMENT}/region-cross-delay/regions/:id/city`

if (
  process.env.REACT_APP_ENV === 'development' ||
  process.env.REACT_APP_ENV === 'test' ||
  process.env.REACT_APP_ENV === undefined
) {
  //POINITNG TO STATIC JSON FOR DEVELOPMENT - AS BACKEND DOESNT SUPPORT CORS
  BORDER_CROSS_DELAY_API_ENDPOINT = `border-cross-delays.json`;
  OCEAN_PORT_CONGESTION_DETAILS_ENDPOINT = `ocean-port-congestion-details.json`;
  REGIONAL_DELAY_UPDATES_ENDPOINT = `region-cross-delays.json`;
  REGIONAL_CITY_DELAY_UPDATES_ENDPOINT = `region-city-cross-delays.json`;
  REGIONAL_CITY_INSIGHTS_ENDPOINT = `region-city-insights.json`;
}

export const fetchingBorderCrossingEventsUpdates = (): BorderCrossEventAction => {
  return {
    type: FETCH_BORDER_CROSSING_EVENTS_UPDATES,
  };
};

export const fetchingBorderCrossingDelayUpdates = (): BorderCrossDelayAction => {
  return {
    type: FETCH_BORDER_CROSSING_DELAY_UPDATES,
    payload: axios.get(BORDER_CROSS_DELAY_API_ENDPOINT),
  };
};

export const fetchingOceanPortCongestionDetails = (): OceanPortAddressesAction => {
  return {
    type: FETCH_OCEAN_PORT_ADDRESSES,
    payload: axios.get(OCEAN_PORT_CONGESTION_DETAILS_ENDPOINT),
  };
};

export const fetchRegionalDelayUpdates = (): RegionalDelayAction => {
  return {
    type: FETCH_REGIONAL_DELAY_UPDATES,
    payload: axios.get(REGIONAL_DELAY_UPDATES_ENDPOINT),
  };
};

export const fetchRegionalCityDelayUpdates = (
  id: string = "",
): RegionalCityDelayAction => {
  return {
    type: FETCH_REGIONAL_CITY_DELAY_UPDATES,
    payload: axios.get(REGIONAL_CITY_DELAY_UPDATES_ENDPOINT.replace(/:id/, id)),
  }
}

export const changeRegionalDisplayMode = (
  displayMode: string,
): { type: string; payload: string } => {
  return {
    type: CHANGE_REGIONAL_DISPLAY_MODE,
    payload: displayMode,
  };
};

export const syncLiveBorderCrossingDelay = (): { type: string } => {
  return {
    type: SYNC_LIVE_TIME_UPDATES,
  };
};

export const setActiveMode = (
  mode: string,
): { type: string; payload: string } => {
  return {
    type: SELECTED_MODE,
    payload: mode,
  };
};

export const fetchCityInsights = (
  id: string = "",
): CityInsightsAction => {
  return {
    type: FETCH_CITY_INSIGHTS,
    payload: axios.get(REGIONAL_CITY_INSIGHTS_ENDPOINT.replace(/:id/, id)),
  }
}
