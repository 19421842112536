import React from 'react';
import './MapExplore.scss';

const baseUrl:string = `https://www.fourkites.com/`;
const expectedSrc = '/assets/img/book_solid.svg';
interface ExplorePopoverProps {
  className: string;
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

interface ExploreState {
  showExplore: boolean;
}

interface ExploreProps {
  type?: string;
}

function navigateToRealTimeVisibility(): void {
	const URL = baseUrl + `platform/real-time-visibility/`;
	window.open(URL, '_blank');
}

function navigateToDynamicYard(): void {
	const URL = baseUrl + `platform/yard-management/`;
	window.open(URL, '_blank');
}
function navigateToGetDemo(): void {
	const URL = baseUrl + `demo/`;
	window.open(URL, '_blank');
}

export default class Explore extends React.Component<ExploreProps, ExploreState> {
  constructor(props: any) {
    super(props);
    this.state = {
      showExplore: false,
    };
  }

  toggleClass() {
    const bool = this.state.showExplore;
    this.setState({
      showExplore: !bool,
    });
  }

  get ExploreClass():string {
    return this.state.showExplore ? 'show' : 'hide';
  }

  handleClick = () => {
    this.toggleClass();
	}
	

  render() {
    let ExplorePopover = null;
    ExplorePopover = <ExplorePopoverElement className={this.ExploreClass} onClick={this.handleClick}/>
    return (
      <section>
        <div className="Explore-wrapper">
          <button className="explore" onClick={this.toggleClass.bind(this) } onMouseOut={this.toggleClass.bind(this)}>Explore </button>
        </div>
        {ExplorePopover}
      </section>
    );
  }
}

function ExplorePopoverElement(props: ExplorePopoverProps) {
		console.log("props", props);
		console.log("props-classname", props.className);

  return (
    <div className={`Explore-popup-wrapper ${props.className}`}>
      <div className="title">Learn more about Fourkites</div>
			<div className="point">
				<div className="content real-time" onClick={()=>navigateToRealTimeVisibility()}>REAL-TIME VISIBILITY</div>
				<div className="content" onClick={()=>navigateToDynamicYard()}>DYNAMIC YARD</div>
				<div className="content-one margin-top">The State of Yard Management:</div>
				<div className="content-one"><a href="https://www.fourkites.com/the-state-of-yard-management-industry-report/" target="_blank">2021 Industry Report</a></div>
				<div className="content-two"><button className="button"  onClick={()=>navigateToGetDemo()}>Get a Demo</button></div>
			</div>
    </div>
  );
}
