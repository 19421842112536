import React, { useState } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import {
  RegionTrend,
} from '../../../types/choropleth-types';
import './IndustryTrendChart.scss';
import {
  Link,
} from "react-router-dom";
import * as _ from 'lodash';
import { INDUSTRY_COLORS, COLORS, REJECT_INDUSTRIES } from '../../../constants';
import { Industry } from 'types/choropleth-types';
import MultiSelect from "react-multi-select-component";

type IndustryTrendChartProps = {
  regionTrend: RegionTrend;
  currentMode: string
};

const IndustryTrendChart = (props: IndustryTrendChartProps): JSX.Element => {
  const stateList = Object.keys(props.regionTrend.trends).filter((iny) => (!REJECT_INDUSTRIES.includes(iny)));
  const onIndustrySelect = (selected: any[]) => {
    setSelectedIndustryList(selected.map((i)=>(i.value)));
  };
  const [selectedIndustryList, setSelectedIndustryList] = useState(stateList.filter((i)=>(i!=='Other')));
  const data = (() => {
    if(!props.regionTrend || _.isEmpty(props.regionTrend.trends)) return null;
    let trendsList = [];
    const randomState = _.first(stateList) as string
    let len = props.regionTrend.trends[randomState][props.currentMode].length;
    for(let i = 0; i < len; i++){
      let trendItem: {[key:string]: any} = {};
      for (let stateName in props.regionTrend.trends) {
        let loadTrend: any;
        let dwellTrend: any;
        try{
          loadTrend = props.regionTrend.trends[stateName][props.currentMode][i];
          dwellTrend = props.regionTrend.trends[stateName][`dwell_${props.currentMode}`][i];// 
          if(!trendItem['name']){
            trendItem['name'] = loadTrend.label;
          };
          const loadValue = parseFloat(loadTrend.value);
          const dwellValue = parseFloat(dwellTrend.value)
          trendItem[props.currentMode] = isNaN(loadValue) ? null : loadValue;
          trendItem[`dwell_${props.currentMode}`] = isNaN(dwellValue) ? null : dwellValue;
        } catch {
          console.warn(`failed to parse ${stateName} at ${i}`);
          trendItem[props.currentMode] = null;
        }
      }
      trendsList.push(trendItem);
    }
    return trendsList;
  })();

  function renderIndustrySelector(){
    const stateData = stateList.map((ind)=> ({ label: ind, value: ind }));
    const selectedIndustryData = stateData.filter((ind => (selectedIndustryList.includes(ind.value))));
    return (
      <div className="industry-selector">
        <MultiSelect
            options={stateData}
            value={selectedIndustryData}
            onChange={onIndustrySelect}
            labelledBy={"Select"}
            hasSelectAll={false}
          />
      </div>
    )
  }

  function getStrokeColor(field: number) {
    let color = "#636363";
    if(isNaN(field) || field===0) return color;
    if(field<0) { color = "#E24E48"; }
    if(field>0) { color = "#539257"; }
    return color
  }

  const renderTooltip = (props:any) => {
    let dataKey0,dataKey1,label,stroke0,stroke1 = ""
    let value0,value1 = " "
    if (props && props.payload && props.payload[0]) {
      if(Object.keys(props.payload[0] || {}).length > 0){
        dataKey0 = props.payload[0].dataKey || "";
        label = props.payload[0].payload["name"] || "";
        stroke0 = props.payload[0].stroke || "E24E48";
        value0 = props.payload[0].payload[dataKey0] || " ";
      }
      if(Object.keys(props.payload[1] || {}).length > 0){
        dataKey1 = props.payload[1].dataKey || "";
        stroke1 = props.payload[1].stroke || "539257";
        value1 = props.payload[1].payload[dataKey1] || " ";
      }
    return(<div className="recharts-custom-tooltip">
      <div>{label}</div><div>
        <span style={{color:stroke0}}>{value0}</span>
        <span style={{color:stroke1}}>, {value1}</span>% change compared to previous 2 weeks</div>
    </div>)
    }
    return null
  }

  const ColoredDot = (props: {colorStroke: string}) => {
    return (
      <svg height="10" width="10">
        <circle cx="5" cy="5" r="4" fill={props.colorStroke} />
      </svg>
    )
  }

  function renderTrendChart() {
    const loadStroke = getStrokeColor(-1);
    const dwellStroke = getStrokeColor(1)
    return (
      <div className="industry-trend">
        <div className="title">Trends</div>
          <LineChart width={470} height={320} data={data as object[]}>
            <XAxis dataKey="name"/>
            <YAxis label={{ value: '% Change', angle: -90, position: 'inside' }} />
            <CartesianGrid strokeDasharray="3 3"/>
            <Tooltip 
              itemStyle={{color:"white !important"}} 
              content={renderTooltip}
              />
            <Line type="monotone" dataKey={props.currentMode} stroke={loadStroke} unit={"%"} connectNulls={true} strokeWidth={2} />
            <Line type="monotone" dataKey={`dwell_${props.currentMode}`} stroke={dwellStroke} unit={"%"} connectNulls={true} strokeWidth={2} />
          </LineChart>
          <div style={{textAlign:"center",fontSize:"12px",marginTop:"4px"}}>
            <ColoredDot colorStroke={loadStroke}/> Truck Movement &nbsp;
            <ColoredDot colorStroke={dwellStroke}/> Dwell Times</div>
      </div>
    )
  }

  return (
    <div className="choro-item detail-panel">
      <Link to={"/regional-insights"} className="title">
        <i className="fas fa-chevron-right"></i>
        <span>{props.regionTrend.state.name}</span>
      </Link>
      <div className="content">
        {renderTrendChart()}
      </div>
    </div>
  );
}

export default IndustryTrendChart;