function isNumber(value: string): boolean {
  return !isNaN(parseInt(value, 10));
}

function hasBeenChanged(value: string): boolean {
  return value.includes('%') || value === 'No Data';
}

function populateNoData(value: string): string {
  return !hasBeenChanged(value) && !isNumber(value) ? 'No Data' : value;
}

function humanifyValue (value: string) {
  const text = `${(parseFloat(value) < 0 ? 'below' : 'above')} 2 weeks moving average`;
  const displayValue = Math.abs(parseFloat(value))
  return `${displayValue}% ${text}`;
}

function addPercent(value: string): string {
  return !hasBeenChanged(value) && isNumber(value) ? humanifyValue(value) : value;
}

export function scrubValue(value: string): string {
  return addPercent(populateNoData(value));
}
