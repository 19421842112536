import { FETCH_BORDER_CROSSING_EVENTS_UPDATES } from '../constants';
import { BorderCrossEventAction } from '../types/border-cross-actions';
import { BORDER_CROSS_EVENT_UPDATES } from '../factories/border-cross-updates.mock';
const initialState = {
  pending: false,
  eventsUpdates: [],
  error: null,
};
export function BorderCrossingEventsReducer(
  state = initialState,
  action: BorderCrossEventAction,
) {
  switch (action.type) {
    case FETCH_BORDER_CROSSING_EVENTS_UPDATES:
      return {
        ...state,
        pending: false,
        error: null,
        ...BORDER_CROSS_EVENT_UPDATES,
      };
  }
  return state;
}
