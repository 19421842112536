import React from 'react';
import './BorderEventUpdates.scss';
import { BorderEventUpdate } from '../../../types/border-cross-types';

function renderEventUpdates(updates: any) {
  return updates.map((data: BorderEventUpdate, i: number) => {
    return (
      <div className="Update" key={i}>
        <label>{data.dateTime}</label>
        <p>{data.update}</p>
      </div>
    );
  });
}

const headerLabel = 'Recent Updates';

function BorderEventUpdates(props: any) {
  const updates = props.borderCrossingEventsUpdates;
  // console.log('EVENT UPDATES', props.borderCrossingEventsUpdates);
  return (
    <div className="Border-event-updates-wrapper">
      <div className="Border-event-updates">
        <div className="Heading">
          <label>{headerLabel}</label>
        </div>
        {renderEventUpdates(updates)}
      </div>
      <button className="Slider-toggle" onClick={(ev) => toggleClass(ev)}>
        <i className="fas fa-caret-left"></i>
      </button>
    </div>
  );
}

function toggleClass(ev: any) {
  const dom: any = document.querySelector('div.Border-event-updates-wrapper');
  dom.style.flexBasis = '0%';
}

export default BorderEventUpdates;
