import { GENERAL_UTIL } from '../../../utils/general-util';
import { TILE_LAYER_URL } from 'common/constants/map-config.constants';

const modeObj = GENERAL_UTIL.getThemeObj();

export const mapDefaults = {
  zoom: 10,
  animate: true,
  zoomControl: false,
  minZoom: 2,
  maxZoom: 18,
  scrollWheelZoom: true,
  lightTileLayer: TILE_LAYER_URL({
    resource: 'trucknopttile',
    scheme: 'reduced.day',
    resolution: 512,
    ppi: 400,
  }),
  darkTileLayer: TILE_LAYER_URL({
    resource: 'trucknopttile',
    scheme: 'reduced.night',
    resolution: 512,
    ppi: 400,
  }),
  mode: modeObj.className,
  modeValue: modeObj.value,
};
