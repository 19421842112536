import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import logger from 'redux-logger';
import { initialState } from './init-state';
import rootReducer from '../reducers/index';

let middleware;
if (
  process.env.REACT_APP_ENV === 'development' ||
  process.env.REACT_APP_ENV === undefined
) {
  middleware = applyMiddleware(thunk, logger, promise);
} else {
  middleware = applyMiddleware(thunk, promise);
}
const store = createStore(rootReducer, initialState, middleware);

export default store;
