import React from 'react';
import { scrubValue } from './formatters/toolTipFormatter';
import './ChoroplethTooltip.scss';

import { RegionTraffic } from 'types/choropleth-types';

type ChoroplethTooltip = {
  className: string;
  name: string;
  regionDelay: RegionTraffic;
  displayMode: string;
  colorKey: string
};

function vectorClass(field: number, lableStyle: any){
  if(isNaN(field) || !field) return null;
  if(field<0) return (
    <i style={lableStyle} className="far fa-long-arrow-down"></i>
  );
  return (
    <i style={lableStyle} className="far fa-long-arrow-up"></i>
  );
}

function getLableStyle(field: number) {
  let color = "#8F8F8F";
  switch(field){
    case 1:
    case 2:
    case 3:
      color = "#539257"
      break
    case 4:
      color = "#4f7752"
      break
    case -1:
    case -2:
    case -3:
      color = "#E24E48"
      break
    case -4:
      color = "#B74043"
      break
  }
  return color
}

const ToolTipContent = (props: any)=> {
  const NumericValue = parseFloat(props.field);
  return(<div className={'tooltip-value'}>
  {isNaN(NumericValue) ? (<div className={'tooltip-value'}>No data available</div>) : (<div className={'tooltip-value'}>{vectorClass(NumericValue, props.textColor)} {scrubValue(props.field)}</div>)}
  </div>)
}

function renderField(loadVolume: string, loadColor: string, dwellTime: string, dwellColor: string, lable: string): JSX.Element | null {
  dwellTime = loadVolume === "" ? loadVolume : dwellTime;
  dwellColor = loadVolume === "" ? loadColor : dwellColor;
  const loadTextstyle = {
    color: getLableStyle(parseFloat(loadColor))
  };
  const dwellTextstyle = {
    marginTop: "8px",
    color: getLableStyle(parseFloat(dwellColor))
  };
  return (
    <div className={'tooltip-content'}>
      <div className={'tooltip-lable'}  >Truck Movement</div>
      <ToolTipContent field={loadVolume} textColor={loadTextstyle} />
      <div className={'tooltip-lable'}  >Dwell Time</div>
      <ToolTipContent field={dwellTime} textColor={dwellTextstyle} />
    </div>
  ); 
}

const ChoroplethTooltip = (props: ChoroplethTooltip): JSX.Element => {
  const { inbound, inboundColor, outbound, outboundColor, dwellInbound, dwellOutbound, dwellInboundColor, dwellOutboundColor } = props.regionDelay;
  const name = props.name;
  function fieldsToRender(){
    if(props.displayMode === 'inbound'){
      return renderField(inbound, inboundColor, dwellInbound, dwellInboundColor, 'Inbound')
    } else {
      return renderField(outbound, outboundColor, dwellOutbound, dwellOutboundColor, 'Outbound')
    }
  }

  return (
    <div className="tooltip-container">
      <div className={'tooltip-title'}>{name}</div>
      {fieldsToRender()}
    </div>
  );
};

export default ChoroplethTooltip;
