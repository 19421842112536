import React from 'react';
import './App.scss';
import Header from './common/components/Header/Header';
import Footer from './common/components/Footer/Footer';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import BorderCrossing from './app/components/BorderCrossing/BorderCrossing';
// import PortCongestion from './app/components/PortCongestion/PortCongestion';
import CityState from './app/components/CityState/CityState';
import {
  ROUTE_BORDER_CROSSING,
  // ROUTE_PORT_CONGESTION,
  ROUTE_REGIONAL_INSIGHTS,
} from './constants';

function App() {
  return (
    <Router>
      <div className="Flex-container" id="Border-Wait-App">
        <Header />
        <div className="Content-wrapper">
          <Switch>
            <Route path={`${process.env.PUBLIC_URL}/${ROUTE_BORDER_CROSSING}`}>
              <BorderCrossing />
            </Route>
            {/* <Route path={`${process.env.PUBLIC_URL}/${ROUTE_PORT_CONGESTION}`}>
              <PortCongestion />
            </Route> */}
            <Route
              path={`${process.env.PUBLIC_URL}/${ROUTE_REGIONAL_INSIGHTS}`}
            >
              <CityState />
            </Route>
            <Route exact path={`${process.env.PUBLIC_URL}`}>
              <Redirect to={`/${ROUTE_REGIONAL_INSIGHTS}`} />
            </Route>
          </Switch>
        </div>
        <Footer key="2" />
      </div>
    </Router>
  );
}

export default App;
