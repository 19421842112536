import { RegionProperties } from '../../../types/choropleth-types';
import { FeatureCollection } from 'geojson';
import { titleCase } from '../../../constants/formatters';
import * as _ from 'lodash';

const defaultDelay = {
  inbound: null,
  inboundColor: 0,
  outbound: null,
  outboundColor: 0,
  emptyMiles: null,
  emptyMilesColor: 0,
  dwellInbound: null,
  dwellInboundColor: 0,
  dwellOutbound: null,
  dwellOutboundColor: 0
};

function mapDataToGeojson(
  dataFromApi: RegionProperties[],
  geoJson: FeatureCollection,
): FeatureCollection {
  const mergedData = geoJson;

  dataFromApi.forEach((region) => {
    const foundFeature = mergedData.features.find((feature) => {
      return (
        feature.properties &&
        feature.properties.name.toLowerCase() === region.name.toLowerCase()
      );
    });
    if (foundFeature && foundFeature.properties) {
      foundFeature.properties.name = titleCase(foundFeature.properties.name);
      foundFeature.properties.code = region.code;
      foundFeature.properties.delay = region.delay;
      foundFeature.properties.id = region.id;
    }
  });
  return mergedData;
}

function addDefaultsToFeatureCollection(
  featureCollection: FeatureCollection,
): FeatureCollection {
  const dataWithDefaults = featureCollection;

  dataWithDefaults.features.forEach((feature) => {
    if (feature.properties && !feature.properties.delay) {
      feature.properties.delay = defaultDelay;
    }
  });
  return featureCollection;
}

export function mapDataToRegion(
  dataFromApi: RegionProperties[],
  geoJsonRegions: FeatureCollection,
): FeatureCollection {
  const mappedData = mapDataToGeojson(dataFromApi, geoJsonRegions);
  const mappedWithDefaults = addDefaultsToFeatureCollection(mappedData);

  return mappedWithDefaults;
}

export function getFeatureWithProperties(region: RegionProperties, geoJsonRegions: FeatureCollection): FeatureCollection {
  const filteredRegions: FeatureCollection = _.cloneDeep(geoJsonRegions);
  filteredRegions.features = filteredRegions.features.filter((feature) => {
    if(
      feature.properties && feature.properties.name &&
      feature.properties.name.toLowerCase() === region.name.toLowerCase()
    ){
      feature.properties.name = titleCase(feature.properties.name);
      feature.properties.code = region.code;
      feature.properties.delay = region.delay;
      feature.properties.id = region.id;
      return true;
    }
    return false;
  });
  return filteredRegions;
}
